// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/utils';
import * as Actions from './actions';

const initialState = {
  // Get All Discount
  isFirstGetAllDiscounts: false,
  isGetAllDiscountsRequest: false,
  isGetAllDiscountsSuccess: false,
  isGetAllDiscountsFailure: false,
  getAllDiscountsState: {},
  // Get customer vouchers
  isGetCustomerVoucherRequest: false,
  isGetCustomerVoucherSuccess: false,
  isGetCustomerVoucherFailure: false,
  getCustomerVoucherState: {},
  // Get All Discount Product
  isFirstGetAllDiscountsProduct: false,
  isGetAllDiscountsProductRequest: false,
  isGetAllDiscountsProductSuccess: false,
  isGetAllDiscountsProductFailure: false,
  getAllDiscountsProductState: {},
  // Create Discount
  isCreateDiscountRequest: false,
  isCreateDiscountSuccess: false,
  isCreateDiscountFailure: false,
  // Create Discount Product
  isCreateDiscountProductRequest: false,
  isCreateDiscountProductSuccess: false,
  isCreateDiscountProductFailure: false,
  // Update Discount
  isUpdateDiscountRequest: false,
  isUpdateDiscountSuccess: false,
  isUpdateDiscountFailure: false,

  // Update Discount Product
  isUpdateDiscountProductRequest: false,
  isUpdateDiscountProductSuccess: false,
  isUpdateDiscountProductFailure: false,

  // Delete Discount
  isDeleteDiscountRequest: false,
  isDeleteDiscountSuccess: false,
  isDeleteDiscountFailure: false,

  // Delete Discount Product
  isDeleteDiscountProductRequest: false,
  isDeleteDiscountProductSuccess: false,
  isDeleteDiscountProductFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Discount
    [Actions.getAllDiscountsRequest]: (state) => ({
      ...state,
      isGetAllDiscountsRequest: true,
      isGetAllDiscountsSuccess: false,
      isGetAllDiscountsFailure: false,
    }),
    [Actions.getAllDiscountsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllDiscounts: true,
      isGetAllDiscountsRequest: false,
      isGetAllDiscountsSuccess: true,
      isGetAllDiscountsFailure: false,
      getAllDiscountsState: payload,
    }),
    [Actions.getAllDiscountsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllDiscountsRequest: false,
      isGetAllDiscountsSuccess: false,
      isGetAllDiscountsFailure: true,
      errorMessages: payload,
    }),
    // #region : Get All Discount Product
    [Actions.getAllDiscountsProductRequest]: (state) => ({
      ...state,
      isGetAllDiscountsProductRequest: true,
      isGetAllDiscountsProductSuccess: false,
      isGetAllDiscountsProductFailure: false,
    }),
    [Actions.getAllDiscountsProductSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllDiscountsProduct: true,
      isGetAllDiscountsProductRequest: false,
      isGetAllDiscountsProductSuccess: true,
      isGetAllDiscountsProductFailure: false,
      getAllDiscountsProductState: payload,
    }),
    [Actions.getAllDiscountsProductFailure]: (state, { payload }) => ({
      ...state,
      isGetAllDiscountsProductRequest: false,
      isGetAllDiscountsProductSuccess: false,
      isGetAllDiscountsProductFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region: Get Customer Voucher
    [Actions.getCustomerVoucherRequest]: (state) => ({
      ...state,
      isGetCustomerVoucherRequest: true,
      isGetCustomerVoucherSuccess: false,
      isGetCustomerVoucherFailure: false,
    }),
    [Actions.getCustomerVoucherSuccess]: (state, { payload }) => ({
      ...state,
      isGetCustomerVoucherRequest: false,
      isGetCustomerVoucherSuccess: true,
      isGetCustomerVoucherFailure: false,
      getCustomerVoucherState: payload,
    }),
    [Actions.getCustomerVoucherFailure]: (state, { payload }) => ({
      ...state,
      isGetCustomerVoucherRequest: false,
      isGetCustomerVoucherSuccess: false,
      isGetCustomerVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCustomerVoucherState]: (state) => ({
      ...state,
      isGetCustomerVoucherRequest: false,
      isGetCustomerVoucherSuccess: false,
      isGetCustomerVoucherFailure: false,
      getCustomerVoucherState: {},
    }),
    // #region : Create Discount
    [Actions.createDiscountRequest]: (state) => ({
      ...state,
      isCreateDiscountRequest: true,
      isCreateDiscountSuccess: false,
      isCreateDiscountFailure: false,
    }),
    [Actions.createDiscountSuccess]: (state, { payload }) => {
      const getAllDiscountsStateTmp = handleUpdateDataList(state.getAllDiscountsState, payload.data, 'create');

      return ({
        ...state,
        isCreateDiscountRequest: false,
        isCreateDiscountSuccess: true,
        isCreateDiscountFailure: false,
        getAllDiscountsState: { ...getAllDiscountsStateTmp },
      });
    },
    [Actions.createDiscountFailure]: (state, { payload }) => ({
      ...state,
      isCreateDiscountRequest: false,
      isCreateDiscountSuccess: false,
      isCreateDiscountFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateDiscount]: (state) => ({
      ...state,
      isCreateDiscountRequest: false,
      isCreateDiscountSuccess: false,
      isCreateDiscountFailure: false,
    }),

    // #endregion
    // #region : Create Discount
    [Actions.createDiscountProductRequest]: (state) => ({
      ...state,
      isCreateDiscountProductRequest: true,
      isCreateDiscountProductSuccess: false,
      isCreateDiscountProductFailure: false,
    }),
    [Actions.createDiscountProductSuccess]: (state, { payload }) => {
      const getAllDiscountsStateTmp = handleUpdateDataList(state.getAllDiscountsProductState, payload.data, 'create');

      return ({
        ...state,
        isCreateDiscountProductRequest: false,
        isCreateDiscountProductSuccess: true,
        isCreateDiscountProductFailure: false,
        getAllDiscountsProductState: { ...getAllDiscountsStateTmp },
      });
    },
    [Actions.createDiscountProductFailure]: (state, { payload }) => ({
      ...state,
      isCreateDiscountProductRequest: false,
      isCreateDiscountProductSuccess: false,
      isCreateDiscountProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateDiscountProduct]: (state) => ({
      ...state,
      isCreateDiscountProductRequest: false,
      isCreateDiscountProductSuccess: false,
      isCreateDiscountProductFailure: false,
    }),
    // #endregion
    // #region : Update Discount
    [Actions.updateDiscountRequest]: (state) => ({
      ...state,
      isUpdateDiscountRequest: true,
      isUpdateDiscountSuccess: false,
      isUpdateDiscountFailure: false,
    }),
    [Actions.updateDiscountSuccess]: (state, { payload }) => {
      const getAllDiscountsStateTmp = handleUpdateDataList(state.getAllDiscountsState, payload.data, 'update');

      return ({
        ...state,
        isUpdateDiscountRequest: false,
        isUpdateDiscountSuccess: true,
        isUpdateDiscountFailure: false,
        getAllDiscountsState: { ...getAllDiscountsStateTmp },
      });
    },
    [Actions.updateDiscountFailure]: (state, { payload }) => ({
      ...state,
      isUpdateDiscountRequest: false,
      isUpdateDiscountSuccess: false,
      isUpdateDiscountFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateDiscount]: (state) => ({
      ...state,
      isUpdateDiscountRequest: false,
      isUpdateDiscountSuccess: false,
      isUpdateDiscountFailure: false,
    }),

    // #region : Update Discount Product
    [Actions.updateDiscountProductRequest]: (state) => ({
      ...state,
      isUpdateDiscountProductRequest: true,
      isUpdateDiscountProductSuccess: false,
      isUpdateDiscountProductFailure: false,
    }),
    [Actions.updateDiscountProductSuccess]: (state, { payload }) => {
      const getAllDiscountsStateTmp = handleUpdateDataList(state.getAllDiscountsProductState, payload.data, 'update');

      return ({
        ...state,
        isUpdateDiscountProductRequest: false,
        isUpdateDiscountProductSuccess: true,
        isUpdateDiscountProductFailure: false,
        getAllDiscountsProductState: { ...getAllDiscountsStateTmp },
      });
    },
    [Actions.updateDiscountProductFailure]: (state, { payload }) => ({
      ...state,
      isUpdateDiscountProductRequest: false,
      isUpdateDiscountProductSuccess: false,
      isUpdateDiscountProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateDiscountProduct]: (state) => ({
      ...state,
      isUpdateDiscountProductRequest: false,
      isUpdateDiscountProductSuccess: false,
      isUpdateDiscountProductFailure: false,
    }),
    // #endregion
    // #region : Delete Discount
    [Actions.deleteDiscountRequest]: (state) => ({
      ...state,
      isDeleteDiscountRequest: true,
      isDeleteDiscountSuccess: false,
      isDeleteDiscountFailure: false,
    }),
    [Actions.deleteDiscountSuccess]: (state, { payload }) => {
      const getAllDiscountsStateTmp = handleUpdateDataList(state.getAllDiscountsState, payload, 'delete');

      return ({
        ...state,
        isDeleteDiscountRequest: false,
        isDeleteDiscountSuccess: true,
        isDeleteDiscountFailure: false,
        getAllDiscountsState: { ...getAllDiscountsStateTmp },
      });
    },
    [Actions.deleteDiscountFailure]: (state, { payload }) => ({
      ...state,
      isDeleteDiscountRequest: false,
      isDeleteDiscountSuccess: false,
      isDeleteDiscountFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteDiscount]: (state) => ({
      ...state,
      isDeleteDiscountRequest: false,
      isDeleteDiscountSuccess: false,
      isDeleteDiscountFailure: false,
    }),

    // #endregion
    // #region : Delete Discount Product
    [Actions.deleteDiscountProductRequest]: (state) => ({
      ...state,
      isDeleteDiscountProductRequest: true,
      isDeleteDiscountProductSuccess: false,
      isDeleteDiscountProductFailure: false,
    }),
    [Actions.deleteDiscountProductSuccess]: (state, { payload }) => {
      const getAllDiscountsStateTmp = handleUpdateDataList(state.getAllDiscountsProductState, payload, 'delete');

      return ({
        ...state,
        isDeleteDiscountProductRequest: false,
        isDeleteDiscountProductSuccess: true,
        isDeleteDiscountProductFailure: false,
        getAllDiscountsProductState: { ...getAllDiscountsStateTmp },
      });
    },
    [Actions.deleteDiscountProductFailure]: (state, { payload }) => ({
      ...state,
      isDeleteDiscountProductRequest: false,
      isDeleteDiscountProductSuccess: false,
      isDeleteDiscountProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteDiscountProduct]: (state) => ({
      ...state,
      isDeleteDiscountProductRequest: false,
      isDeleteDiscountProductSuccess: false,
      isDeleteDiscountProductFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetDiscountState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
