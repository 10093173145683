const vnLangPromotion = {
  'promotion.helmet': 'Danh sách khuyến mãi',
  'promotion.breadcrumb.title': 'Danh sách khuyến mãi',
  'promotion.modal.create.title': 'Thêm chương trình khuyến mãi',
  'promotion.code.label': 'Mã khuyến mãi',
  'promotion.title.label': 'Tiêu đề',
  'promotion.image.label': 'Hình ảnh',
  'promotion.start.label': 'Ngày bắt đầu',
  'promotion.end.label': 'Ngày kết thúc',
  'promotion.title.label-modal': 'Tiêu đề',
  'promotion.title.label-modal-placehoder': 'Nhập tiêu đề',
  'promotion.message.label-modal': 'Mô tả',
  'promotion.message.label-modal-placehoder': 'Nhập mô tả',
  'promotion.from.label-modal': 'Ngày bắt đầu',
  'promotion.to.label-modal': 'Ngày kết thúc',
  'promotion.from.placeholder': 'Nhập ngày bắt đâu',
  'promotion.to.placeholder': 'Nhập ngày kết thúc',
  'promotion.content.label-modal': 'Nội dung',
  'promotion.choose.product': 'Chọn sản phẩm',
  'promotion.add.product': 'Thêm sản phẩm',
  'promotion.noti.delete.success': 'Xoá khuyến mãi thành công !',
  'promotion.noti.update.success': 'Cập nhật khuyến mãi thành công !',
  'promotion.noti.create.success': 'Tạo mã khuyến mãi thành công !',
  'promotion.active.label': 'Trạng thái hoạt động',
  'promotion.list.product.label': 'Sản phẩm đã chọn',
  'promotion.modal.create.confirm.title': 'Xác nhận thêm khuyến mãi mới',
  'promotion.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm khuyến mãi này?',
  'promotion.modal.update.confirm.title': 'Xác nhận cập nhật khuyến mãi này',
  'promotion.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật khuyến mãi này?',
  'promotion.modal.delete.confirm.title': 'Xác nhận xóa khuyến mãi này',
  'promotion.modal.delete.confirm.description': 'Bạn chắc chắn muốn xóa khuyến mãi này?',
  'promotion.price.discount': 'Giá giảm',
  'promotion.price.origin': 'Giá gốc',

};

export default vnLangPromotion;
