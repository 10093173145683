const usLangWarehouse = {
  'warehouse.helmet': 'List of warehouses',
  'warehouse.breadcrumb.title': 'List of warehouses',
  'warehouse.breadcrumb.parent': 'Sell',
  'warehouse.modal.create.title': 'Add warehouse',
  'warehouse.modal.create.confirm.title': 'Confirm new warehouses',
  'warehouse.modal.create.confirm.description': 'You definitely want to add this warehouse?',
  'warehouse.modal.detail.title': 'the detail information of warehouse',
  'warehouse.modal.update.title': 'Update warehouse information',
  'warehouse.modal.update.confirm.title': 'Confirmation of warehouse information updates',
  'warehouse.modal.update.confirm.description': 'You definitely want to update this warehouse information?',
  'warehouse.modal.delete.title': 'warehouse deletion confirmation',
  'warehouse.modal.delete.description': 'You definitely want to delete this warehouse?',
  'warehouse.modal.reset-password.title': 'Reset Password',
  'warehouse.noti.create.success': 'Add successful warehouses!',
  'warehouse.noti.create.failure': 'Add unsuccessful warehouses!Please try again later.',
  'warehouse.noti.update.success': 'Update the warehouse successfully!',
  'warehouse.noti.update.failure': 'Update the warehouse unsuccessful!Please try again later.',
  'warehouse.noti.delete.success': 'Delete warehouses successfully!',
  'warehouse.noti.delete.failure': 'Delete warehouses failed!Please try again later.',
  'warehouse.noti.reset-password.success': 'Retite a successful warehouse password!',
  'warehouse.noti.reset-password.failure': 'Reset the warehouse password failed!Please try again later.',
  'warehouse.btn-create': 'Create',
  'warehouse.error-min-length': 'Minimum $x character!',
  'warehouse.error-max-length': 'Maximum $x character!',
  'warehouse.code.col': 'warehouse code',
  'warehouse.code.label': 'warehouse code',
  'warehouse.code.placeholder': 'Enter the warehouse code',
  'warehouse.barcode.col': 'Barcode',
  'warehouse.barcode.label': 'Barcode',
  'warehouse.barcode.placeholder': 'Enter the barcode',
  'warehouse.categories.col': 'warehouse line',
  'warehouse.categories.label': 'warehouse line',
  'warehouse.categories.placeholder': 'Choose a warehouse line',
  'warehouse.categories.error.required': 'Please choose the warehouse line!',
  'warehouse.name.col': "warehouse's name",
  'warehouse.name.label': "warehouse's name",
  'warehouse.name.placeholder': 'Enter the name of the warehouse',
  'warehouse.name.error.required': 'Please enter the warehouse name!',
  'warehouse.desc.col': 'Describe',
  'warehouse.desc.label': 'warehouse Description',
  'warehouse.desc.placeholder': 'Enter the warehouse description',
  'warehouse.unit.col': 'Unit',
  'warehouse.unit.label': 'Basic unit',
  'warehouse.unit.placeholder': 'Enter the basic unit',
  'warehouse.unit.error-required': 'Please enter the basic unit!',
  'warehouse.price.col': 'Price',
  'warehouse.price.label': 'Price (VND)',
  'warehouse.price.placeholder': 'Enter the selling price',
  'warehouse.price.error-required': 'Please enter the selling price!',
  'warehouse.price.invalid': 'Enter the price greater than 0!',
  'warehouse.units.name.label': 'Unit name',
  'warehouse.units.name.placeholder': 'Enter the name of the unit',
  'warehouse.units.name.error-required': 'Please enter the name of the unit!',
  'warehouse.units.number.label': 'Exchange value',
  'warehouse.units.number.placeholder': 'Enter the conversion value',
  'warehouse.units.number.error-required': 'Please enter the conversion value!',
  'warehouse.units.number.error-invalid': 'The conversion value must be greater than 0!',
  'warehouse.units.price.label': 'Price (VND)',
  'warehouse.units.price.placeholder': 'Enter the selling price',
  'warehouse.units.price.error-required': 'Please enter the selling price!',
  'warehouse.units.code.label': 'warehouse code',
  'warehouse.units.code.placeholder': 'Enter the warehouse code',
  'warehouse.units.barcode.label': 'Barcode',
  'warehouse.units.barcode.placeholder': 'Enter the barcode',
  'warehouse.image.col': 'Image',
  'warehouse.image.label': 'Upload',
  'warehouse.image.label-2': 'warehouse image',
  'warehouse.image.error-required': 'Please upload the warehouse photo!',
  'warehouse.btn-add-units-items': 'Add unit',
  'warehouse.email.validate': 'Email not valid format',
  'warehouse.phone.label': 'warehouse phone',
  'warehouse.phone.placeholder': 'Enter warehouse phone',
  'warehouse.email.label': 'warehouse email',
  'warehouse.email.placeholder': 'Enter warehouse email',
  'warehouse.address.label': 'Address',
  'warehouse.address.placeholder': 'Enter warehouse address ',
  'warehouse.address.work-place': 'Location',
};

export default usLangWarehouse;
