const vnLangWorkConfig = {
  'work-config.schedule.week': 'Lịch làm việc tuần',
  'work-config.schedule.month': 'Lịch làm việc tháng',
  'work-config.division': 'Phân công công việc',
  'work-config.sidebar': 'Lịch tuần',
  'work-config.sidebar.month': 'Lịch tháng',
  'work-config.hetmet': 'Lịch làm việc - Quản lý người dùng',
  'work-config.breadcrumb-title': 'Danh sách lịch làm việc',
  'work-config.breadcrumb-parent': 'Lịch làm việc',
  'work-config.btn-add': 'Thêm ca làm việc',
  'work-config.noti-create-success-title': 'Thêm lịch làm việc mới thành công!',
  'work-config.noti-create-failure-title': 'Thêm lịch làm việc mới không thành công! Vui lòng thử lại sau.',
  'work-config.noti-update-success-title': 'Cập nhật lịch làm việc thành công!',
  'work-config.noti-update-failure-title': 'Cập nhật lịch làm việc không thành công! Vui lòng thử lại sau.',
  'work-config.noti-delete-success-title': 'Xoá lịch làm việc thành công!',
  'work-config.noti-delete-failure-title': 'Xoá lịch làm việc không thành công! Vui lòng thử lại sau.',
  'work-config.modal-create-title': 'Thêm lịch làm việc',
  'work-config.modal-create-btn': 'Thêm mới',
  'work-config.modal-create-confirm-title': 'Xác nhận thêm lịch làm việc mới',
  'work-config.modal-create-confirm-sub-title': 'Bạn chắc chắn muốn thêm lịch làm việc này?',
  'work-config.modal-update-title': 'Cập nhật lịch làm việc',
  'work-config.modal-update-btn': 'Cập nhật',
  'work-config.modal-update-confirm-title': 'Xác nhận cập nhật lịch làm việc',
  'work-config.modal-update-confirm-sub-title': 'Bạn chắc chắn muốn cập nhật lịch làm việc này?',
  'work-config.modal-detail-title': 'Chi tiết lịch làm việc',
  'work-config.modal-detail-btn': 'Cập nhật lịch làm việc',
  'work-config.modal-delete-title': 'Xoá lịch làm việc',
  'work-config.modal-delete-btn': 'Xoá',
  'work-config.modal-delete-confirm-title': 'Xác nhận xoá lịch làm việc',
  'work-config.modal-delete-confirm-sub-title': 'Bạn chắc chắn muốn xoá lịch làm việc này?',
  'work-config.label-user': 'Nhân viên',
  'work-config.label-user.placeholder': 'Tất cả nhân viên',
  'work-config.label-apply': 'Ngày áp dụng',
  'work-config.label-apply.error': 'Ngày áp dụng phải bắt đầu từ ngày mai trở đi!',
  'work-config.label-place': 'Thêm nhân viên vào lịch làm việc chung đang áp dụng',
  'work-config.label-place.placeholder': 'Chọn địa điểm làm việc',
  'work-config.label-place.required': 'Vui lòng chọn địa điểm làm việc!',
  'work-config.label-place.placeholder-2': 'Chọn ca làm việc trong ngày',
  'work-config.label-mon': 'Thứ 2',
  'work-config.label-mon.err': 'Vui lòng chọn ca làm việc cho Thứ 2!',
  'work-config.label-tue': 'Thứ 3',
  'work-config.label-tue.err': 'Vui lòng chọn ca làm việc cho Thứ 3!',
  'work-config.label-wed': 'Thứ 4',
  'work-config.label-wed.err': 'Vui lòng chọn ca làm việc cho Thứ 4!',
  'work-config.label-thu': 'Thứ 5',
  'work-config.label-thu.err': 'Vui lòng chọn ca làm việc cho Thứ 5!',
  'work-config.label-fri': 'Thứ 6',
  'work-config.label-fri.err': 'Vui lòng chọn ca làm việc cho Thứ 6!',
  'work-config.label-sat': 'Thứ 7',
  'work-config.label-sat.err': 'Vui lòng chọn ca làm việc cho Thứ 7!',
  'work-config.label-sun': 'Chủ nhật',
  'work-config.label-sun.err': 'Vui lòng chọn ca làm việc cho Chủ nhật!',

  'work-config.book': 'Thêm ca làm việc',
  'work-config.book.confirm': 'Xác nhận thêm lịch làm việc',
  'work-config.book.confirmSure': 'Bạn chắc chắn thêm lịch làm việc',
  'work-config.change.success': 'Thay đổi lịch làm việc thành công',

  'work-config.label-kitchen': 'Phụ trách',
  'work-config.label-upate-gift': 'Cập nhật quà tặng',
  'work-config.label-add-gift': 'Thêm quà tặng',

};

export default vnLangWorkConfig;
