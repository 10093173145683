const usLangPromotion = {
  'promotion.helmet': 'List promotions',
  'promotion.breadcrumb.title': 'List promotions',
  'promotion.modal.create.title': 'Add promotion',
  'promotion.code.label': 'Code promotion',
  'promotion.title.label': 'Title',
  'promotion.image.label': 'Image',
  'promotion.start.label': 'Start',
  'promotion.end.label': 'End',
  'promotion.title.label-modal': 'Title',
  'promotion.title.label-modal-placehoder': 'Input title',
  'promotion.message.label-modal': 'Message',
  'promotion.message.label-modal-placehoder': 'Input message',
  'promotion.from.label-modal': 'Start',
  'promotion.to.label-modal': 'End',
  'promotion.from.placeholder': 'Input start date',
  'promotion.to.placeholder': 'Input end date',
  'promotion.content.label-modal': 'Content',
  'promotion.choose.product': 'Choose product',
  'promotion.add.product': 'Add product',
  'promotion.noti.delete.success': 'Delete promotion success !',
  'promotion.noti.update.success': 'Update promotion success !',
  'promotion.noti.create.success': 'Create promotion success !',
  'promotion.active.label': 'Active status',
  'promotion.list.product.label': 'List products',
  'promotion.modal.create.confirm.title': 'Confirm add new promotion',
  'promotion.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm khuyến mãi này?',
  'promotion.modal.update.confirm.title': 'Confirm update promotion',
  'promotion.modal.update.confirm.description': 'You definitely want to update this promotion information?',
  'promotion.modal.delete.confirm.title': 'Confirm delete promotion',
  'promotion.price.discount': 'Discount',
  'promotion.price.origin': 'Origin price',
};

export default usLangPromotion;
