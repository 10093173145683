/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllDiscounts({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/discounts?${payload}`)
        : () => axiosMicro.get('/discounts', { params: payload }),
    );
    yield put(Actions.getAllDiscountsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllDiscountsFailure(messages));
    }
  }
}
function* getAllDiscountsProduct({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-discounts?${payload}`)
        : () => axiosMicro.get('/product-discounts', { params: payload }),
    );
    yield put(Actions.getAllDiscountsProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllDiscountsProductFailure(messages));
    }
  }
}

function* getCustomerVoucher({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/customer-vouchers', {
      params: payload,
    }));
    yield put(Actions.getCustomerVoucherSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getCustomerVoucherFailure(messages));
    }
  }
}

function* createDiscount({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/discounts', payload));
    yield put(Actions.createDiscountSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createDiscountFailure(messages));
    }
  }
}
function* createDiscountProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-discounts', payload));
    yield put(Actions.createDiscountProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createDiscountProductFailure(messages));
    }
  }
}

function* updateDiscount({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/discounts/${payload.id}?_method=patch`, payload.body));
    yield put(Actions.updateDiscountSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateDiscountFailure(messages));
    }
  }
}

function* updateDiscountProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/product-discounts/${payload.id}?_method=patch`, payload.body));
    yield put(Actions.updateDiscountProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateDiscountProductFailure(messages));
    }
  }
}

function* deleteDiscount({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/discounts/${payload}`));
    yield put(Actions.deleteDiscountSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteDiscountFailure(messages));
    }
  }
}

function* deleteDiscountProduct({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/product-discounts/${payload}`));
    yield put(Actions.deleteDiscountProductSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteDiscountProductFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllDiscountsRequest, getAllDiscounts);
  yield takeLatest(Actions.getAllDiscountsProductRequest, getAllDiscountsProduct);
  yield takeLatest(Actions.createDiscountRequest, createDiscount);
  yield takeLatest(Actions.createDiscountProductRequest, createDiscountProduct);
  yield takeLatest(Actions.updateDiscountRequest, updateDiscount);
  yield takeLatest(Actions.updateDiscountProductRequest, updateDiscountProduct);
  yield takeLatest(Actions.deleteDiscountRequest, deleteDiscount);
  yield takeLatest(Actions.getCustomerVoucherRequest, getCustomerVoucher);
  yield takeLatest(Actions.deleteDiscountProductRequest, deleteDiscountProduct);
}
