const contract = {
  'contract.expire': 'Contract expire',
  'contract.code': 'Contract code',
  'contract.info': 'Information contract',
  'contract.list': 'List contract',
  'contract.edit': 'Edit contract',
  'contract.trial': 'Trial(days)',
  'contract.trial.7': '7 day',
  'contract.trial.15': '15 day',
  'contract.delete': 'Delete contract',
  'contract.manage': 'Manage contract',
  'contract.create': 'Create contract',
  'contract.create.confirm': 'Confirm create contract',
  'contract.create.confirmSure': 'You will definitely create contract?',
  'contract.create.success': 'Create contract successfully',
  'contract.edit.confirm': 'Confirm edit contract',
  'contract.edit.confirmSure': 'You will definitely edit contract?',
  'contract.update.success': 'Update contract successfully',
  'contract.field': 'Field',
  'contract.price': 'Price contract',
  'contract.active': 'Active contract',
  'contract.active.success': 'Active contract successfully',
  'contract.status': 'Contract status',
  'contract.new': 'New',
  'contract.accepted': 'Accepted',
  'contract.denied': 'Denied',
  'contract.expiry': 'Upcoming expiry contract',
  'contract.expired': 'Expired contract',
  'contract.active.confirm': 'Confirm active contract',
  'contract.active.confirmSure': 'You will definitely active contract?',
  'contract.id_front': 'Id card front',
  'contract.id_back': 'Id card back',
  'contract.user-process': 'Processor',
  'contract.histories': 'History of contract browsing',
  'contract.package': 'Service pack',

  // validate

  'contract.name.required': 'Please enter a custom name',
  'contract.field.required': 'Please enter a field',
  'contract.package.required': 'Please choose the service package',
  'contract.price.required': 'Please enter price contract',
  'contract.id_card.required': 'PLease enter id card',
  'contract.id_card_date.required': 'Please enter id card date',
  'contract.from_date.required': 'Please enter from date',
  'contract.to_date.required': 'Please enter to date',
};

const usLangContract = {
  ...contract,
};

export default usLangContract;
