const vnLangSaler = {
  'saler.helmet': 'Danh sách nhân viên kinh doanh',
  'saler.breadcrumb.title': 'Danh sách nhân viên kinh doanh',
  'saler.breadcrumb.parent': 'Quản lý người dùng',
  'saler.modal.create.title': 'Thêm nhân viên kinh doanh',
  'saler.modal.create.confirm.title': 'Xác nhận thêm nhân viên kinh doanh mới',
  'saler.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm nhân viên kinh doanh này?',
  'saler.modal.detail.title': 'Thông tin chi tiết nhân viên kinh doanh',
  'saler.modal.update.title': 'Cập nhật thông tin nhân viên kinh doanh',
  'saler.modal.update.confirm.title': 'Xác nhận cập nhật thông tin nhân viên kinh doanh',
  'saler.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin nhân viên kinh doanh này?',
  'saler.modal.delete.title': 'Xác nhận xoá nhân viên kinh doanh',
  'saler.modal.delete.description': 'Bạn chắc chắn muốn xoá nhân viên kinh doanh này?',
  'saler.modal.reset-password.title': 'Đặt lại mật khẩu',
  'saler.noti.create.success': 'Thêm nhân viên kinh doanh thành công!',
  'saler.noti.create.failure': 'Thêm nhân viên kinh doanh không thành công! Vui lòng thử lại sau.',
  'saler.noti.update.success': 'Cập nhật nhân viên kinh doanh thành công!',
  'saler.noti.update.failure': 'Cập nhật nhân viên kinh doanh không thành công! Vui lòng thử lại sau.',
  'saler.noti.delete.success': 'Xoá nhân viên kinh doanh thành công!',
  'saler.noti.delete.failure': 'Xoá nhân viên kinh doanh không thành công! Vui lòng thử lại sau.',
  'saler.noti.reset-password.success': 'Đặt lại mật khẩu nhân viên kinh doanh thành công!',
  'saler.noti.reset-password.failure': 'Đặt lại mật khẩu nhân viên kinh doanh không thành công! Vui lòng thử lại sau.',
  'saler.btn-create': 'Thêm',
  'saler.name.col': 'Họ và tên',
  'saler.name.label': 'Họ và tên',
  'saler.name.placeholder': 'Nhập họ và tên',
  'saler.name.error-required': 'Vui lòng nhập Họ và tên!',
  'saler.name.error-min-length': 'Tối thiểu $x ký tự!',
  'saler.name.error-max-length': 'Tối đa $x ký tự!',
  'saler.phone.col': 'Số điện thoại',
  'saler.phone.label': 'Số điện thoại',
  'saler.phone.placeholder': 'Nhập số điện thoại',
  'saler.phone.error-required': 'Vui lòng nhập Số điện thoại!',
  'saler.phone.error-invalid': 'Số điện thoại không hợp lệ!',
  'saler.email.col': 'Gmail',
  'saler.email.label': 'Gmail',
  'saler.email.placeholder': 'Nhập gmail',
  'saler.email.error-required': 'Vui lòng nhập Gmail!',
  'saler.email.error-invalid': 'Gmail không hợp lệ!',
  'saler.password.label': 'Mật khẩu',
  'saler.password.placeholder': 'Nhập mật khẩu',
  'saler.password.error-required': 'Vui lòng nhập Mật khẩu!',
  'config.noti.create.success': 'Cập nhật cấu hình thành công!',
};

export default vnLangSaler;
