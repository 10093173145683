const staff = {
  'staff.create': 'Tạo nhân viên',
  'staff.list': 'Danh sách nhân viên',
  'staff.edit': 'Sửa nhân viên',
  'staff.delete': 'Xóa nhân viên',
  'staff.manage': 'Quản lý nhân viên',
  'staff.create.confirm': 'Xác nhận tạo nhân viên',
  'staff.create.confirmSure': 'Bạn chắc chắn tạo nhân viên?',
  'staff.edit.confirm': 'Xác nhận sửa nhân viên',
  'staff.edit.confirmSure': 'Bạn chắc chắn sửa nhân viên?',
  'staff.delete.confirm': 'Xác nhận xóa nhân viên',
  'staff.delete.confirmSure': 'Bạn chắc chắn xóa nhân viên?',
  'staff.create.success': 'Tạo nhân viên thành công',
  'staff.update.success': 'Cập nhật nhân viên thành công',
  'staff.delete.success': 'Xoá nhân viên thành công',
  'staff.work': 'Loại nhân viên',
  'staff.parttime': 'Bán thời gian',
  'staff.fulltime': 'Toàn thời gian',
  'staff.resetSuccess': 'Đặt lại mật khẩu nhân viên thành công',
  'staff.currentWorkTime': 'Ca trực hôm nay',
  'staff.cumulative': 'Số giờ tích lũy',
  'staff.cumulative-date': 'Số ngày tích lũy',
  'staff.wage': 'Tiền công tích lũy',
  'staff.settled': 'Đã kết toán',
  'staff.disabled.confirm': 'Xác nhận vô hiệu hóa nhân viên',
  'staff.disabled.confirmSure': 'Bạn chắc chắn vô hiệu hóa nhân viên này??',
  'staff.active.confirm': 'Xác nhận kích hoạt nhân viên',
  'staff.active.confirmSure': 'Bạn chắc chắn kích hoạt nhân viên?',
  'staff.basic_salary': 'Lương cơ bản',
  'staff.basic_salary.hours': 'Lương theo giờ',
  'staff.basic_salary.month': 'Lương theo tháng',
  'staff.work_per_day': 'Giờ làm mỗi ngày',
  'staff.work_per_month': 'Ngày làm mỗi tháng',
  'staff.ot': 'Tăng ca',
  'staff.allowance': 'Trợ cấp',
  'staff.break': 'Số giờ nghỉ trưa',
  'staff.tax': 'Thuế',
  'staff.social_security': 'Bảo hiểm xã hội',
  'staff.health_insurance': 'Bảo hiểm y tế',
  'staff.salary.previous': 'Tiền thừa/thiếu kỳ trước',
  'staff.enable': 'Đang hoạt động',
  'staff.disable': 'Vô hiệu hóa',
  'staff.block': 'Khóa TK',
  'staff.unblock': 'Kích hoạt',
  'staff.pay': 'Thanh toán',
  'staff.pay.all': 'Trả hết',
  'staff.pay.once': 'Thanh toán 1 phần',
  'staff.remunerate': 'Thưởng',
  'staff.punish': 'Phạt',
  'staff.salary.history': 'Lịch sử thanh toán tiền công',
  'staff.salary.pay': 'Thanh toán lương nhân viên',
  'staff.salary.change': 'Điều chỉnh lương',
  'staff.salary.enter': 'Nhập số tiền trả nhân viên',
  'staff.salary.pre_period': 'Lương tồn kỳ trước',
  'staff.salary.prepay': 'Trả trước',
  'staff.salary.history-wage': 'Lịch sử thanh toán',
  'staff.salary.date-wage': 'Ngày thanh toán',
  'staff.salary.money-wage': 'Tiền công',
  'staff.salary.total-time': 'Tổng giờ',
  'staff.salary.work-time': 'giờ làm',
  'staff.salary.bonus': 'Khen thưởng',
  'staff.salary.deduction': 'Phạt',
  'staff.salary.advance': 'Ứng lương',
  'staff.salary.excess-lack': 'Thừa/thiếu',
  'staff.salary.total-money': 'Tổng tiền',
  'staff.salary.employee.payments': 'Số tiền thanh toán nhân viên',
  'staff.salary.paid': 'Đã trả',
  'staff.salary.remaining.amount': 'Số tiền còn lại',
  'staff.salary.dont-wage-pay-history': 'Nhân viên chưa có lịch sử thanh toán tiền công',
  'staff.salary.total-bonus': 'Tồng tiền thưởng',
  'staff.salary.time-job': 'giờ công',
  'staff.salary.date-job': 'Ngày công',
  'staff.salary.accumulation-time': 'Số giờ tích lũy',
  'staff.salary.invalid-money': 'Số tiền trả lớn hơn số tiền thực nhận',
  'staff.salary.invalid-date': 'Ngày không hợp lệ',
  'staff.salary.confirm-pay': 'Xác nhận thanh toán',
  'staff.salary.confirm-pay-part': 'Xác nhận thanh toán một phần',
  'staff.salary.confirm-pay-sure': 'Bạn có chắc chắn thanh toán?',
  'staff.salary.no-work-time': 'Không có ca',
  'staff.resetPassword.success': 'Khôi phục mật khẩu nhân viên thành công',
};

const vnLangStaff = {
  ...staff,
};

export default vnLangStaff;
