const vnLangAgency = {
  'agency.helmet': 'Danh sách đại lý',
  'agency.breadcrumb.title': 'Danh sách đại lý',
  'agency.breadcrumb.parent': 'Bán hàng',
  'agency.modal.create.title': 'Thêm đại lý',
  'agency.modal.create.confirm.title': 'Xác nhận thêm đại lý mới',
  'agency.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm đại lý này?',
  'agency.modal.detail.title': 'Thông tin chi tiết đại lý',
  'agency.modal.update.title': 'Cập nhật thông tin đại lý',
  'agency.modal.update.confirm.title': 'Xác nhận cập nhật thông tin đại lý',
  'agency.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin đại lý này?',
  'agency.modal.delete.title': 'Xác nhận xoá đại lý',
  'agency.modal.delete.description': 'Bạn chắc chắn muốn xoá đại lý này?',

  'agency.noti.create.success': 'Thêm đại lý thành công!',
  'agency.noti.create.failure': 'Thêm đại lý không thành công! Vui lòng thử lại sau.',
  'agency.noti.update.success': 'Cập nhật đại lý thành công!',
  'agency.noti.update.failure': 'Cập nhật đại lý không thành công! Vui lòng thử lại sau.',
  'agency.noti.delete.success': 'Xoá đại lý thành công!',
  'agency.noti.delete.failure': 'Xoá đại lý không thành công! Vui lòng thử lại sau.',

  'agency.btn-create': 'Thêm',
  'agency.error-min-length': 'Tối thiểu $x ký tự!',
  'agency.error-max-length': 'Tối đa $x ký tự!',

  'agency.name.col': 'Tên đại lý',
  'agency.name.label': 'Tên đại lý',
  'agency.name.placeholder': 'Nhập tên đại lý',
  'agency.name.error.required': 'Vui lòng nhập tên đại lý!',
  'agency.desc.col': 'Mô tả',
  'agency.desc.label': 'Mô tả đại lý',
  'agency.price.label': 'Giá đại lý',
  'agency.desc.placeholder': 'Nhập mô tả đại lý',
  'agency.price.placeholder': 'Nhập giá đại lý',

  'agency.image.col': 'Hình ảnh',
  'agency.image.label': 'Tải lên',
  'agency.image.label-2': 'Hình ảnh đại lý',
  'agency.image.error-required': 'Vui lòng tải lên ảnh đại lý!',

  'agency.level.col': 'Cấp đại lý',
  'agency.level.placeholder': 'Nhập cấp đại lý',
  'agency.name.product.label': 'Tên sản phẩm',
  'agency.price.product.label': 'Giá sản phẩm',
  'agency.discount.percentage.product.label': 'Phân trăm chiết khấu',
  'agency.general.discount.col': 'Chiết khấu chung',
  'agency.general.discount.placeholder': 'Nhập chiết khẩu chung',
  'agency.search.placeholder': 'Tìm kiếm đơn vị chiết khấu',
};

export default vnLangAgency;
