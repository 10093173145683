const imports = {
  'import.main.info.title': 'Import information',
  'import.main.info.date': 'Import date',
  'import.main.info.user': 'Importer',
  'import.supplier': 'Supplier',
  'import.warehouse': 'Into storage',
  'import.search.placeholder': 'Product code / product name',
  'import.search.order.placeholder': 'Code orders',
  'import.main-product-import.title': 'Product information',
  'import.main-product-import.imei': 'Serial number/ imei',
  'import.main-product-import.number': 'The number of import',

  'import.search.input.number.placeholder': 'Input number',
  'import.search.input.price.placeholder': 'Input price',

  'import.main-product-import.price': 'Entering price (1 items)',
  'import.main-product-import.supplier': 'Supplier',
  'import.main-product-import.imei.required': 'Please enter Imei',
  'import.main-product-import.number.required': 'Please enter the number',
  'import.main-product-import.price.required': 'Please enter the import price',
  'import.main-product-import.date.required': 'Please enter the expiry date',
  'import.main-product-import.name': 'Products name',
  'import.main-product-import.date': 'Expiry',
  'import.main-product-import.date.notApply': 'Do not apply the expiration date',
  'import.main.cancel': 'Cancellate',
  'import.main.cancel.confirm': 'Confirmation of change',
  'import.main.cancel.confirmSure': 'You definitely cancel this change?',
  'import.main.create': 'Import',
  'import.main.create.success': 'Successful warehousing',
  'import.main.create.confirm': 'Confirmation of warehousing',
  'import.main.create.confirmSure': 'You definitely enter warehouse?',
  'import.main.error.overLength': 'The number of imei codes has exceeded the number of products you enter',
  // 'import.main.error.enough': 'S',
  'import.main.complete': 'Complete',
  'import.main.cancel-change': 'Cancel changes',
  'import.main.import-imei': 'Enter imei',
  'import.main.import': 'Import',

  'import.main.table-stt': 'Ordinal number',
  'import.main.table-name-product': 'Product name',
  'import.main.table-number-product': 'Number import',
  'import.main.table-price-product': 'Price import',
  'import.main.table-expiry-product': 'Expiry',
};

const list = {
  'import-list.name': 'Products name',
  'import-list.number': 'Quantity ',
  'import-list.unit': 'Unit',
  'import-list.price': 'Import price',
  'import-list.imei': 'Code IMEI/Serial',
  'import-list.date': 'Expiration date',
  'import-list.style.col': 'Form',
  'import-list.money-number.col': 'Amount & quantity',
  'import-list.supplier.col': 'Supplier',
  'import-list.user.col': 'Importer',
  'import-list.import': 'Import',
  'import-list.export': 'Output',
  'import-list.helmet': 'List of import and export',
  'import-list.breadcrumb.title': 'List of import and export',
  'import-list.breadcrumb.parent': 'List of import and export',
};

const exports = {
  'export.main.info.title': ' Export warehouse information',
  'export.main.info.date': 'Date of inventory',
  'export.main.info.user': 'Exporter',
  'export.warehouse.title': 'Export warehouse',
  'export.supplier': 'Supplier',
  'export-list.price': 'Export price',
  'export.warehouse': ' Choose a export warehouse',
  'export.search.placeholder': ' Product code / product name',
  'export.main-product-export.title': 'Product information',
  'export.main-product-export.imei': ' Serial number/ imei',
  'export.main-product-export.number': 'The number of import',
  'export.main-product-export.price': ' Entering price (1 items)',
  'export.main-product-export.supplier': 'Supplier',
  'export.main-product-export.imei.required': ' Please enter the iMei code',
  'export.main-product-export.number.required': ' Please enter the number',
  'export.main-product-export.price.required': ' Please enter the price',
  'export.main-product-export.date.required': ' Please enter the expiry date',
  'export.main-product-export.name': 'Products name',
  'export.main-product-export.date': 'Expiry',
  'export.main.cancel': 'Cancel changes',
  'export.main.cancel.confirm': ' Confirmation of cancellation',
  'export.main.cancel.confirmSure': ' You definitely cancel this change?',
  'export.main.create': ' Export',
  'export.main.create.success': ' Successful warehouse',
  'export.main.create.confirm': ' Confirmation of warehouse',
  'export.main.create.confirmSure': ' You definitely enter the warehouse?',
  'export.main.error.overLength': 'The number of imei codes has exceeded the number of products you enter',
  // 'export.main.error.enough': 's',
  'export.main.complete': 'Complete',
  'export.main.cancel-change': 'Cancel changes',
  'export.main.import-imei': 'Enter imei',
  'export.main.export': 'Export',

  'export.main.table-stt': 'Ordinal number',
  'export.main.table-name-product': 'Product name',
  'export.main.table-number-product': 'Number export',
  'export.main.table-price-product': 'Price export',
};

const usLangActionWarehouse = {
  ...imports,
  ...list,
  ...exports,
};

export default usLangActionWarehouse;
