const usLangCancelOrder = {
  'cancel.order.helmet': 'Cancellation List',
  'cancel.order.breadcrumb.title': 'Cancellation List',
  'cancel.order.breadcrumb.parent': 'Sell',
  'cancel.order.product.name.label': 'Product cancel',
  'cancel.order.product.image.label': 'Image',
  'cancel.order.modal.update.title': 'Updating cancellation requests',
  'cancel.order.modal.update.reason': 'Reason cancellation',
  'cancel.order.modal.update.confirm': 'Confirm cancellation',
  'cancel.order.modal.update.confirm.success': 'Confirmation of successful cancellation!',
  'cancel.order.modal.update.reject.confirm.success': 'Confirmation reject of successful cancellation!',
  'cancel.order.modal.update.confirm.title': 'Confirm cancellation',
  'cancel.order.modal.update.confirm.description': 'You definitely want to cancel this?',
  'cancel.order.modal.update.reject.confirm.title': 'Confirmation of rejection of cancellation',
  'cancel.order.modal.update.reject.confirm.description': 'You definitely want to decline the cancellation?',
};

export default usLangCancelOrder;
