/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllPromotions({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/promotions?${payload}`)
        : () => axiosMicro.get('/promotions', { params: payload }),
    );
    yield put(Actions.getAllPromotionSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllPromotionFailure(messages));
    }
  }
}
function* createPromotion({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/promotions', payload));
    yield put(Actions.createPromotionSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createPromotionFailure(messages));
    }
  }
}

function* updatePromotion({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/promotions/${payload.id}`, payload.body));
    yield put(Actions.updatePromotionSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updatePromotionFailure(messages));
    }
  }
}

function* deletePromotion({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/promotions/${payload}`));
    yield put(Actions.deletePromotionSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deletePromotionFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllPromotionRequest, getAllPromotions);
  yield takeLatest(Actions.createPromotionRequest, createPromotion);
  yield takeLatest(Actions.updatePromotionRequest, updatePromotion);
  yield takeLatest(Actions.deletePromotionRequest, deletePromotion);
}
