const vnLangCashier = {
  'sidebar.cashier': 'Thu ngân',
  'sidebar.book.table': 'Danh sách đặt bàn',
  'sidebar.order.history': 'Lịch sử đặt bàn',
  'list-book-table-all.breadcrumb.title': 'Danh sách đơn đặt bàn',
  'list-book-order-all.breadcrumb.parent': 'Quản lý đơn đặt bàn',
  'list-book-table-all.breadcrumb.title.history': 'Lịch sử đặt bàn',
  'list-orders-history.breadcrumb': 'Quản lý lịch sử đặt bàn',
  'list-orders-all.btn.update': 'Cập nhật',
  'list-orders-all.modal.update.confirm.title': 'Xác nhận cập nhật thông tin đặt bàn',
  'list-orders-all.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin đặt bàn này?',
  'list-orders.noti.update.success': 'Cập nhật đơn hàng thành công',
  'list-orders-all.noti.delete.failure': 'Xoá đơn đặt bàn không thành công! Vui lòng thử lại sau.',
  'list-orders-all.modal.delete.title': 'Xác nhận xoá đơn đặt bàn',
  'list-orders-all.modal.delete.description': 'Bạn chắc chắn muốn xoá đơn đặt bàn này?',
  'list-orders.noti.delete.success': 'Xoá đơn hàng thành công!',
  'list-orders-all.modal.order-status.label': 'Hình thức thanh toán',
  'list-orders-all.modal.paid.label': 'Đã thanh toán',
};
export default vnLangCashier;
