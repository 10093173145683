const salary = {
  'salary.title': 'Bảng lương',
  'salary.list': 'Danh sách bảng lương',
  'salary.edit': 'Chỉnh sửa bảng lương',
  'salary.delete': 'Xóa bảo lương',
  'salary.manage': 'Quản lý bảng lương',
  'salary.create': 'Tạo bảng lương',
  'salary.create.confirm': 'Xác nhận tạo bảng lương',
  'salary.create.confirmSure': 'Bạn chắc chắn tạo bảng lương này?',
  'salary.create.success': 'Tạo bảng lương thành công',
  'salary.edit.confirm': 'Xác nhận sửa bảng lương',
  'salary.edit.confirmSure': 'Bạn chắc chắn sửa bảng lương này?',
  'salary.update.success': 'Cập nhật bảng lương thành công',
  'salary.delete.confirm': 'Xác nhận xóa bảng lương',
  'salary.delete.confirmSure': 'Bạn chắc chắn xóa bảng lương này?',
  'salary.delete.success': 'Xóa bảng lương thành công',
  'salary.staff.choose': 'Chọn nhân viên',
  'salary.bonus': 'Thưởng',
  'salary.deduction': 'Khấu trừ',
  'salary.amount': 'Số tiền',
  'salary.advance': 'Ứng lương',
  'salary.advance.history': 'Lịch sử',
  'salary.remunerate': 'Thưởng',
  'salary.remunerate.confirm': 'Xác nhận thưởng',
  'salary.remunerate.confirmSure': 'Bạn chắc chắn thưởng?',
  'salary.advance.confirm': 'Xác nhận ứng lương',
  'salary.advance.confirmSure': 'Bạn chắc chắn ứng lương',
  'salary.punish': 'Phạt',
  'salary.punish.confirm': 'Xác nhận phạt',
  'salary.punish.confirmSure': 'Bạn chắc chắn phạt',
  'salary.remunerate.success': 'Thưởng thành công',
  'salary.deduction.success': 'Khấu trừ thành công',
  'salary.real-receipt-to-day': 'Thực nhận đến ngày',
  'salary.vnd': 'VND',
  'salary.money.correspond': 'Vui lòng nhập số tiền tương ứng',
};

const usLangSalary = {
  ...salary,
};

export default usLangSalary;
