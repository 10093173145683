const workOff = {
  'work.off.choose': 'Choose date',
  'work.off.list': 'List work off',
  'work.off.edit': 'Edit work off',
  'work.off.delete': 'Delete work off',
  'work.off.manage': 'Manage work off',
  'work.off.create': 'Create work off',
  'work.off.create.confirm': 'Confirm create work off',
  'work.off.create.confirmSure': 'You will definitely create work off?',
  'work.off.create.success': 'Create work off successfully',
  'work.off.edit.confirm': 'Confirm edit work off',
  'work.off.edit.confirmSure': 'You will definitely edit work off?',
  'work.off.delete.confirm': 'Confirm delete work off',
  'work.off.delete.confirmSure': 'You will definitely delete work off?',
  'work.off.update.success': 'Update work off successfully',
  'work.off.delete.success': 'Delete work off successfully',

  'work.off.waiting': 'Waiting',
  'work.off.accept': 'Accept',
  'work.off.deny': 'Deny',
  'work.off.label-paid-leave': 'Paid leave',
  'work.off.label-unpaid-leave': 'Unpaid leave',
  'work.off.create-bookoff': 'Create alternate schedule',
  'work.off.create-bookoff.confirm': 'Confirm create alternate schedule',
  'work.off.create-bookoff.confirmSure': 'You will definitely create alternate schedule',
  'work.off.create-bookoff.roles': '(Do not select if you want to remove the employee from the shift)',
};

const usLangWorkOff = {
  ...workOff,
};

export default usLangWorkOff;
