const salary = {
  'salary.title': 'Salary',
  'salary.list': 'List salary',
  'salary.edit': 'Edit salary',
  'salary.delete': 'Delete salary',
  'salary.manage': 'Manage salary',
  'salary.create': 'Create salary',
  'salary.create.confirm': 'Confirm create salary',
  'salary.create.confirmSure': 'You will definitely create salary?',
  'salary.create.success': 'Create salary successfully',
  'salary.edit.confirm': 'Confirm edit salary',
  'salary.edit.confirmSure': 'You will definitely edit salary?',
  'salary.update.success': 'Update salary successfully',
  'salary.staff.choose': 'Choose staff',
  'salary.bonus': 'Bonus',
  'salary.deduction': 'Deduction',
  'salary.amount': 'Amount',
  'salary.advance': 'Advance',
  'salary.advance.history': 'History',
  'salary.remunerate': 'Remunerate',
  'salary.remunerate.confirm': 'Confirm remunerate',
  'salary.remunerate.confirmSure': 'You will definitely remunerate',
  'salary.remunerate.success': 'Remunerate success',
  'salary.deduction.success': 'Deduction success',
  'salary.advance.confirm': 'Confirm advance',
  'salary.advance.confirmSure': 'You will definitely advance',
  'salary.punish': 'Punish',
  'salary.punish.confirm': 'Confirm punish',
  'salary.punish.confirmSure': 'You will definitely punish',
  'salary.real-receipt-to-day': 'Real receipt to date',
  'salary.vnd': 'VND',
  'salary.money.correspond': 'Please enter the corresponding amount',
};

const usLangSalary = {
  ...salary,
};

export default usLangSalary;
