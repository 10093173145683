/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllAgencyLevel({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/agency-levels?${payload}`)
        : () => axiosMicro.get('/agency-levels', { params: payload }),
    );
    yield put(Actions.getAllAgencyLevelsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllAgencyLevelsFailure(messages));
    }
  }
}

function* createAgencyLevel({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/agency-levels', payload));
    yield put(Actions.createAgencyLevelsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createAgencyLevelsFailure(messages));
    }
  }
}

function* updateAgencyLevel({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/agency-levels/${payload.id}`, payload.body));
    yield put(Actions.updateAgencyLevelsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateAgencyLevelsFailure(messages));
    }
  }
}

function* deleteAgencyLevel({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/agency-levels/${payload}`));
    yield put(Actions.deleteAgencyLevelsSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteAgencyLevelsFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllAgencyLevelsRequest, getAllAgencyLevel);
  yield takeLatest(Actions.createAgencyLevelsRequest, createAgencyLevel);
  yield takeLatest(Actions.updateAgencyLevelsRequest, updateAgencyLevel);
  yield takeLatest(Actions.deleteAgencyLevelsRequest, deleteAgencyLevel);
}
