const employee = {
  //
  'employee.list': 'List employee',
  'employee.manager': 'Manager employee',
  'employee.add': 'Add employee',
  'employee.edit': 'Edit employee',
  'employee.information': 'Information employee',
  'employee.information-contact': 'The contact information',
  'employee.information-personal': 'The personal information',
  'employee.confirm-add': 'Confirm add employees',
  'employee.confirm-sure-add': 'You definitely want to add this employee?',
  'employee.confirm-edit': 'Confirm edit employees',
  'employee.confirm-sure-edit': 'You definitely want to edit this employee?',
  'employee.confirm-delete': 'Confirm delete employees',
  'employee.confirm-sure-delete': 'You definitely want to delete this employee?',
  'employee.confirm-reset': 'Confirm reset password user deletion',
  // form
  'employee.code': 'ID',
  'employee.disable': 'Disable',
  'employee.enable': 'Enable',
  'employee.name': 'Full name',
  'employee.gender': 'Gender',
  'employee.gender.male': 'Male',
  'employee.gender.female': 'Female',
  'employee.birth': 'Birthday',
  'employee.marital-status': 'Marital Status',
  'employee.id-personal-date': 'Card date',
  'employee.id-personal-place': 'Card date',
  'employee.phone': 'Phone number',
  'employee.image': 'Image',
  'employee.password': 'Password',
  'employee.address': 'Address',
  'employee.position': 'Position',
  'employee.label': 'Label',
  'employee.telephone': 'Phone for work',
  'employee.department': 'Department',
  'employee.start-date-work': 'Start date',
  'employee.timeFrom': 'Hours for check in',
  'employee.timeTo': 'Hours for checkout',
  'employee.cv-status': 'Profile status',
  'employee.account-status': 'Account status',
  'employee.status.submitted': 'Submitted',
  'employee.status.unSubmitted': 'UnSubmitted',
  'employee.statusMarital.married': 'Married',
  'employee.statusMarital.single': 'Single',
  'employee.statusGender.male': 'Male',
  'employee.statusGender.female': 'Female',
  'employee.statusGender.unspecified': 'I do not want to be public',
  'employee.role.employee': 'Employee',
  'employee.role.manager': 'Manager',
  'employee.role.admin': 'Admin',

  'employee.resetPassword.default': 'Reset the default password',
  'employee.resetPassword.gmail': 'Send to gmail',
  'employee.resetPassword.way': 'Select way to get new password',
  'employee.resetPassword.newPassword': 'New password :',

  // notification

  'employee.addSuccess': 'Add employee successfully',
  'employee.addFailure': 'Add employee failure',
  'employee.editSuccess': 'Edit employee successfully',
  'employee.editFailure': 'Edit employee failure',
  'employee.deleteSuccess': 'Delete employee successfully',
  'employee.deleteFailure': 'Delete employee failure',
  'employee.resetSuccess': 'Reset password employee successfully',
  'employee.resetFailure': 'Reset password employee failure',

  // validate
  'employee.name-required': 'Please enter the employee',
  'employee.name-min-length': 'At least 4 characters',
  'employee.phone-require': 'Please enter the employee phone number',
  'employee.phone-valid': 'Phone number not valid',
  'employee.phone-length': 'Phone number must not exceed 11 characters',
  'employee.email-require': 'Email is required',
  'employee.email-valid': 'Email is not correctly formatted',
  'employee.email-exist': 'Email has existed',
  'employee.password-require': 'Password is required',
  'employee.password-valid': 'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character',
  //
  'employee.config.breadcrumb.heading': 'List check in schedule',
  'employee.config.breadcrumb.parent': 'Manager check in schedule',
  'employee.config.helmet': 'List check in schedule',
  'employee.config.create': 'Add check in schedule',
  'employee.config.save.success': 'Add check in schedule success',
  'employee.config.save.failure': 'Add check in schedule failure',
};

const enLangEmployee = {
  ...employee,
};

export default enLangEmployee;
