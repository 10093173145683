const workOff = {
  'work.off.choose': 'Chọn ngày nghỉ',
  'work.off.list': 'Danh sách lịch nghỉ',
  'work.off.edit': 'Cập nhật lịch nghỉ',
  'work.off.delete': 'Xóa lịch nghỉ',
  'work.off.manage': 'Quản lý ca làm',
  'work.off.create': 'Tạo lịch nghỉ',
  'work.off.create.confirm': 'Xác nhận tạo lịch nghỉ',
  'work.off.create.confirmSure': 'Bạn chắc chắn tạo lịch nghỉ?',
  'work.off.create.success': 'Tạo lịch nghỉ thành công',
  'work.off.edit.confirm': 'Xác nhận cập nhật lịch nghỉ',
  'work.off.edit.confirmSure': 'Bạn chắc chắn cập nhật lịch nghỉ?',
  'work.off.update.success': 'Cập nhật lịch nghỉ thành công',
  'work.off.delete.confirm': 'Xác nhận xóa lịch nghỉ',
  'work.off.delete.confirmSure': 'Bạn chắc chắn xóa lịch nghỉ?',
  'work.off.delete.success': 'Xóa lịch nghỉ thành công',

  'work.off.waiting': 'Chờ duyệt',
  'work.off.accept': 'Chấp nhận',
  'work.off.deny': 'Từ chối',
  'work.off.label-paid-leave': 'Nghỉ có lương',
  'work.off.label-unpaid-leave': 'Nghỉ không lương',
  'work.off.create-bookoff': 'Tạo lịch thay thế',
  'work.off.create-bookoff.confirm': 'Xác nhận tạo lịch thay thế',
  'work.off.create-bookoff.confirmSure': 'Bạn chắc chắn tạo lịch thay thế?',
  'work.off.create-bookoff.roles': '(Không chọn nếu muốn xóa nhân viên ra khỏi ca)',

};

const vnLangWorkOff = {
  ...workOff,
};

export default vnLangWorkOff;
