const permission = {
  'permission.name': 'Họ và tên',
  'permission.role': 'Vai trò',
  'permission.list': 'Quản lý phân quyền nhân viên',
  'permission.permission': 'Phân quyền',
  'permission.add': 'Phân quyền cho nhân viên',
  'permission.role.add': 'Thêm vai trò cho nhân viên',
  'permission.role.update': 'Cập nhật vai trò cho nhân viên',
  'permission.confirm-add': 'Xác nhận Đính kèm vai trò cho nhân viên',
  'permission.confirm-sure-add': 'Bạn chắc chắn muốn thêm một vai trò cho nhân viên này?',
  'permission.confirm-update': 'Xác nhận vai trò cập nhật cho nhân viên',
  'permission.confirm-sure-update': 'Bạn chắc chắn muốn cập nhật một vai trò cho nhân viên này?',
  'permission.confirm-delete': 'Xác nhận xóa vai trò cho nhân viên',
  'permission.confirm-sure-delete': 'Bạn chắc chắn muốn xóa một vai trò cho nhân viên này?',
  // form
  'permission.email': 'Email nhân viên',
  'permission.email.require': 'Vui lòng chọn nhân viên được ủy quyền',
  'permission.role.require': 'Vui lòng chọn ít nhất 1 vai trò',
  'permission.role.list': 'Danh sách vai trò',
  'permission.role.name': 'Tên vai trò',
  'permission.role.description': 'Mô tả',

  // notifications
  'permission.syncSuccess': 'Ủy quyền thành công',
  'permission.revokeRole': 'Xóa quyền nhân viên thành công',
  'permission.role.addFailure': 'Thêm vai trò cho nhân viên không thành công',
  'permission.role.updateFailure': 'Vai trò cập nhật cho nhân viên không thành công',
  'permission.role.deleteFailure': 'Xóa vai trò cho nhân viên không thành công',
};

const vnLangPermission = {
  ...permission,
};

export default vnLangPermission;
