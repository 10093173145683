const role = {
  'role.name': 'Role name',
  'role.name.require': 'Please enter the role name',
  'role.name.min': 'At least 2 characters',
  'role.name.max': 'Up to 20 characters',
  'role.description.require': 'Please enter a description for the role',
  'role.permissions.require': 'Please select at least 1 permission',
  'role.list': 'Roles list',
  'role.permission': 'Permission list',
  'role.manager': 'Manager roles',
  'role.add': 'Add roles',
  'role.update': 'Update roles',
  'role.list.permission': 'List permission',
  'role.confirm-add': 'Confirm add role',
  'role.confirm-sure-add': 'You will definitely add this role?',
  'role.confirm-delete': 'Confirm delete role',
  'role.confirm-sure-delete': 'You will definitely delete this role?',
  'role.confirm-update': 'Confirm update role',
  'role.confirm-sure-update': 'You will definitely update this role?',
  // notification
  'role.addSuccess': 'Add role successfully',
  'role.updateSuccess': 'Update role successfully',
  'role.deleteSuccess': 'Delete role successfully',
  'role.deleteFailure': 'Delete role is failed',
  'role.updateFailure': 'Update role is failed',
  'role.addFailure': 'Add role failed',
  'role.addFailure.before': 'This role was created before',
};

const usLangRole = {
  ...role,
};

export default usLangRole;
