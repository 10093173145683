// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isSignInRequest: false,
  isSignInSuccess: false,
  isSignInFailure: false,
  userAuthState: {},

  //
  isSignUpRequest: false,
  isSignUpSuccess: false,
  isSignUpFailure: false,
  //
  isChangePasswordRequest: false,
  isChangePasswordSuccess: false,
  isChangePasswordFailure: false,
  //
  isRegisterRequest: false,
  isRegisterSuccess: false,
  isRegisterFailure: false,
  //
  isRequireChangePasswordRequest: false,
  isRequireChangePasswordSuccess: false,
  isRequireChangePasswordFailure: false,
  //
  isSignInWithGoogleRequest: false,
  isSignInWithGoogleSuccess: false,
  isSignInWithGoogleFailure: false,
  //
  isCreateOTPRequest: false,
  isCreateOTPRequestSuccess: false,
  isCreateOTPRequestFailure: false,
  //
  isFirstCheckOTP: false,
  isCheckOTPRequest: false,
  isCheckOTPSuccess: false,
  isCheckOTPFailure: false,
  getCheckOTPState: {},

  //
  isResetUserPasswordRequest: false,
  isResetUserPasswordRequestSuccess: false,
  isResetUserPasswordRequestFailure: false,
  //
  userChat: {},
  //
  errorMessages: [],
  errorResetUser: [],
};

const reducer = handleActions(
  {
    // #region : Sign in
    [Actions.signInRequest]: (state) => ({
      ...state,
      isSignInRequest: true,
      isSignInSuccess: false,
      isSignInFailure: false,
    }),
    [Actions.signInSuccess]: (state, { payload }) => ({
      ...state,
      isSignInRequest: false,
      isSignInSuccess: true,
      isSignInFailure: false,
      userAuthState: payload,
    }),
    [Actions.signInFailure]: (state, { payload }) => ({
      ...state,
      isSignInRequest: false,
      isSignInSuccess: false,
      isSignInFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Change password
    [Actions.changePasswordRequest]: (state) => ({
      ...state,
      isChangePasswordRequest: true,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: false,
    }),
    [Actions.changePasswordSuccess]: (state) => ({
      ...state,
      isChangePasswordRequest: false,
      isChangePasswordSuccess: true,
      isChangePasswordFailure: false,
    }),
    [Actions.changePasswordFailure]: (state, { payload }) => ({
      ...state,
      isChangePasswordRequest: false,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetChangePasswordState]: (state) => ({
      ...state,
      isChangePasswordRequest: false,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Register
    [Actions.registerRequest]: (state) => ({
      ...state,
      isRegisterRequest: true,
      isRegisterSuccess: false,
      isRegisterFailure: false,
    }),
    [Actions.registerSuccess]: (state) => ({
      ...state,
      isRegisterRequest: false,
      isRegisterSuccess: true,
      isRegisterFailure: false,
    }),
    [Actions.registerFailure]: (state, { payload }) => ({
      ...state,
      isRegisterRequest: false,
      isRegisterSuccess: false,
      isRegisterFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRegisterState]: (state) => ({
      ...state,
      isRegisterRequest: false,
      isRegisterSuccess: false,
      isRegisterFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Require change password
    [Actions.requireChangePasswordRequest]: (state) => ({
      ...state,
      isRequireChangePasswordRequest: true,
      isRequireChangePasswordSuccess: false,
      isRequireChangePasswordFailure: false,
    }),
    [Actions.requireChangePasswordSuccess]: (state) => ({
      ...state,
      isRequireChangePasswordRequest: false,
      isRequireChangePasswordSuccess: true,
      isRequireChangePasswordFailure: false,
    }),
    [Actions.requireChangePasswordFailure]: (state, { payload }) => ({
      ...state,
      isRequireChangePasswordRequest: false,
      isRequireChangePasswordSuccess: false,
      isRequireChangePasswordFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRequireChangePasswordState]: (state) => ({
      ...state,
      isRequireChangePasswordRequest: false,
      isRequireChangePasswordSuccess: false,
      isRequireChangePasswordFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Sign in with google
    [Actions.signInWithGoogleRequest]: (state) => ({
      ...state,
      isSignInWithGoogleRequest: true,
      isSignInWithGoogleSuccess: false,
      isSignInWithGoogleFailure: false,
    }),
    [Actions.signInWithGoogleSuccess]: (state, { payload }) => ({
      ...state,
      isSignInWithGoogleRequest: false,
      isSignInWithGoogleSuccess: true,
      isSignInWithGoogleFailure: false,
      userAuthState: payload,
    }),
    [Actions.signInWithGoogleFailure]: (state, { payload }) => ({
      ...state,
      isSignInWithGoogleRequest: false,
      isSignInWithGoogleSuccess: false,
      isSignInWithGoogleFailure: true,
      errorMessages: payload,
    }),

    // #region : Create OTP
    [Actions.createOTPRequest]: (state) => ({
      ...state,
      isCreateOTPRequest: true,
      isCreateOTPRequestSuccess: false,
      isCreateOTPRequestFailure: false,
    }),
    [Actions.createOTPRequestSuccess]: (state) => ({
      ...state,
      isCreateOTPRequest: false,
      isCreateOTPRequestSuccess: true,
      isCreateOTPRequestFailure: false,
    }),
    [Actions.createOTPRequestFailure]: (state, { payload }) => ({
      ...state,
      isCreateOTPRequest: false,
      isCreateOTPRequestSuccess: false,
      isCreateOTPRequestFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOTPState]: (state) => ({
      ...state,
      isCreateOTPRequest: false,
      isCreateOTPRequestSuccess: false,
      isCreateOTPRequestFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : check OTP
    [Actions.checkOtpRequest]: (state) => ({
      ...state,
      isCheckOTPRequest: true,
      isCheckOTPSuccess: false,
      isCheckOTPFailure: false,

    }),
    [Actions.checkOtpRequestSuccess]: (state, { payload }) => ({
      ...state,
      isFirstCheckOTP: true,
      isCheckOTPRequest: false,
      isCheckOTPSuccess: true,
      isCheckOTPFailure: false,
      getCheckOTPState: payload,

    }),
    [Actions.checkOtpRequestFailure]: (state, { payload }) => ({
      ...state,
      isCheckOTPRequest: false,
      isCheckOTPSuccess: false,
      isCheckOTPFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCheckOTPState]: (state) => ({
      ...state,
      isCheckOTPRequest: false,
      isCheckOTPSuccess: false,
      isCheckOTPFailure: false,
      errorMessages: [],
    }),

    // #region : Reset user password
    [Actions.resetUserPasswordRequest]: (state) => ({
      ...state,
      isResetUserPasswordRequest: true,
      isResetUserPasswordRequestSuccess: false,
      isResetUserPasswordRequestFailure: false,
    }),
    [Actions.resetUserPasswordRequestSuccess]: (state) => ({
      ...state,
      isResetUserPasswordRequest: false,
      isResetUserPasswordRequestSuccess: true,
      isResetUserPasswordRequestFailure: false,
    }),
    [Actions.resetUserPasswordRequestFailure]: (state, { payload }) => ({
      ...state,
      isResetUserPasswordRequest: false,
      isResetUserPasswordRequestSuccess: false,
      isResetUserPasswordRequestFailure: true,
      errorResetUser: payload,
    }),
    [Actions.resetUserPasswordState]: (state) => ({
      ...state,
      isResetUserPasswordRequest: false,
      isResetUserPasswordRequestSuccess: false,
      isResetUserPasswordRequestFailure: false,
      errorResetUser: [],
    }),
    // #region user chat
    [Actions.setUserChat]: (state, { payload }) => ({
      ...state,
      userChat: payload,
    }),
    // #endregion

    // #region Sign up
    [Actions.signUpRequest]: (state) => ({
      ...state,
      isSignUpRequest: true,
      isSignUpSuccess: false,
      isSignUpFailure: false,
    }),
    [Actions.signUpSuccess]: (state) => ({
      ...state,
      isSignUpRequest: false,
      isSignUpSuccess: true,
      isSignUpFailure: false,
    }),
    [Actions.signInFailure]: (state, { payload }) => ({
      ...state,
      isSignInRequest: false,
      isSignInSuccess: false,
      isSignInFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSignUp]: (state) => ({
      ...state,
      isSignInRequest: false,
      isSignInSuccess: false,
      isSignInFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetAuthState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
