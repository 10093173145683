// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllAgencyLevelsRequest = createAction('GET_ALL_AGENCY_LEVELS_REQUEST');
export const getAllAgencyLevelsSuccess = createAction('GET_ALL_AGENCY_LEVELS_SUCCESS');
export const getAllAgencyLevelsFailure = createAction('GET_ALL_AGENCY_LEVELS_FAILURE');

export const createAgencyLevelsRequest = createAction('CREATE_AGENCY_LEVELS_REQUEST');
export const createAgencyLevelsSuccess = createAction('CREATE_AGENCY_LEVELS_SUCCESS');
export const createAgencyLevelsFailure = createAction('CREATE_AGENCY_LEVELS_FAILURE');
export const resetCreateAgencyLevels = createAction('RESET_CREATE_AGENCY_LEVELS');

export const updateAgencyLevelsRequest = createAction('UPDATE_AGENCY_LEVELS_REQUEST');
export const updateAgencyLevelsSuccess = createAction('UPDATE_AGENCY_LEVELS_SUCCESS');
export const updateAgencyLevelsFailure = createAction('UPDATE_AGENCY_LEVELS_FAILURE');
export const resetUpdateAgencyLevels = createAction('RESET_UPDATE_AGENCY_LEVELS');

export const deleteAgencyLevelsRequest = createAction('DELETE_AGENCY_LEVELS_REQUEST');
export const deleteAgencyLevelsSuccess = createAction('DELETE_AGENCY_LEVELS_SUCCESS');
export const deleteAgencyLevelsFailure = createAction('DELETE_AGENCY_LEVELS_FAILURE');
export const resetDeleteAgencyLevels = createAction('RESET_DELETE_AGENCY_LEVELS');

export const resetAgencyLevelsState = createAction('RESET_AGENCY_LEVELS_STATE');
