// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/utils';
import * as Actions from './actions';

const initialState = {
  // Get All promotion
  isFirstGetAllPromotions: false,
  isGetAllPromotionsRequest: false,
  isGetAllPromotionsSuccess: false,
  isGetAllPromotionsFailure: false,
  getAllPromotionsState: {},
  // Create promotion
  isCreatePromotionRequest: false,
  isCreatePromotionSuccess: false,
  isCreatePromotionFailure: false,
  // Update Promotion
  isUpdatePromotionRequest: false,
  isUpdatePromotionSuccess: false,
  isUpdatePromotionFailure: false,
  // Delete Discount
  isDeletePromotionRequest: false,
  isDeletePromotionSuccess: false,
  isDeletePromotionFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Promotion
    [Actions.getAllPromotionRequest]: (state) => ({
      ...state,
      isGetAllPromotionsRequest: true,
      isGetAllPromotionsSuccess: false,
      isGetAllPromotionsFailure: false,
    }),
    [Actions.getAllPromotionSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllPromotions: true,
      isGetAllPromotionsRequest: false,
      isGetAllPromotionsSuccess: true,
      isGetAllPromotionsFailure: false,
      getAllPromotionsState: payload,
    }),
    [Actions.getAllPromotionFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPromotionsRequest: false,
      isGetAllPromotionsSuccess: false,
      isGetAllPromotionsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create promotion
    [Actions.createPromotionRequest]: (state) => ({
      ...state,
      isCreatePromotionRequest: true,
      isCreatePromotionSuccess: false,
      isCreatePromotionFailure: false,
    }),
    [Actions.createPromotionSuccess]: (state) => ({
      ...state,
      isCreatePromotionRequest: false,
      isCreatePromotionSuccess: true,
      isCreatePromotionFailure: false,
    }),
    [Actions.createPromotionFailure]: (state, { payload }) => ({
      ...state,
      isCreatePromotionRequest: false,
      isCreatePromotionSuccess: false,
      isCreatePromotionFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePromotion]: (state) => ({
      ...state,
      isCreatePromotionRequest: false,
      isCreatePromotionSuccess: false,
      isCreatePromotionFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update Product
    [Actions.updatePromotionRequest]: (state) => ({
      ...state,
      isUpdatePromotionRequest: true,
      isUpdatePromotionSuccess: false,
      isUpdatePromotionFailure: false,
    }),
    [Actions.updatePromotionSuccess]: (state) => ({
      ...state,
      isUpdatePromotionRequest: false,
      isUpdatePromotionSuccess: true,
      isUpdatePromotionFailure: false,
    }),
    [Actions.updatePromotionFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePromotionRequest: false,
      isUpdatePromotionSuccess: false,
      isUpdatePromotionFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePromotion]: (state) => ({
      ...state,
      isUpdatePromotionRequest: false,
      isUpdatePromotionSuccess: false,
      isUpdatePromotionFailure: false,
      errorMessages: [],
    }),

    // #endregion
    // #region : Delete Promotion
    [Actions.deletePromotionRequest]: (state) => ({
      ...state,
      isDeletePromotionRequest: true,
      isDeletePromotionSuccess: false,
      isDeletePromotionFailure: false,
    }),
    [Actions.deletePromotionSuccess]: (state, { payload }) => {
      const getAllPromotionsStateTmp = handleUpdateDataList(state.getAllPromotionsState, payload, 'delete');

      return ({
        ...state,
        isDeletePromotionRequest: false,
        isDeletePromotionSuccess: true,
        isDeletePromotionFailure: false,
        getAllPromotionsState: { ...getAllPromotionsStateTmp },
      });
    },
    [Actions.deletePromotionFailure]: (state, { payload }) => ({
      ...state,
      isDeletePromotionRequest: false,
      isDeletePromotionSuccess: false,
      isDeletePromotionFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePromotion]: (state) => ({
      ...state,
      isDeletePromotionRequest: false,
      isDeletePromotionSuccess: false,
      isDeletePromotionFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetPromotionState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
