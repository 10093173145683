const vnLangKitchen = {
  'kitchen.helmet': 'Danh sách bếp',
  'kitchen-list.breadcrumb.title': 'Danh sách món ăn',
  'kitchen-dish-cancel.breadcrumb.title': 'Danh sách hủy món',
  'kitchen.modal.create.title': 'Thêm bếp',
  'kitchen.modal.create.confirm.title': 'Xác nhận thêm bếp mới',
  'kitchen.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm bếp này?',
  'kitchen.modal.detail.title': 'Thông tin chi tiết bếp',
  'kitchen.modal.update.title': 'Cập nhật thông tin bếp',
  'kitchen.modal.update.confirm.title': 'Xác nhận cập nhật thông tin bếp',
  'kitchen.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin bếp này?',
  'kitchen.modal.delete.title': 'Xác nhận xoá bếp',
  'kitchen.modal.delete.description': 'Bạn chắc chắn muốn xoá bếp này?',
  'kitchen.noti.create.success': 'Thêm bếp thành công!',
  'kitchen.noti.create.failure': 'Thêm bếp không thành công! Vui lòng thử lại sau.',
  'kitchen.noti.update.success': 'Cập nhật bếp thành công!',
  'kitchen.noti.update.failure': 'Cập nhật bếp không thành công! Vui lòng thử lại sau.',
  'kitchen.noti.delete.success': 'Xoá bếp thành công!',
  'kitchen.noti.delete.failure': 'Xoá bếp không thành công! Vui lòng thử lại sau.',
  'kitchen.btn-create': 'Thêm bếp',
  'kitchen.name.col': 'Tên ban',
  'kitchen.kitchen.col': 'Danh sách bếp',
  'kitchen.name.label': 'Tên bếp',
  'kitchen.name.placeholder': 'Nhập tên bếp',
  'kitchen.name.error.required': 'Vui lòng nhập tên bếp!',
  'kitchen.kitchen.label': 'Khu vực bếp',
  'kitchen.kitchen.placeholder': 'Chọn khu vực bếp',

  'order.code.label': 'Mã HD',
  'kitchen.code.label': 'Mã Bếp',
  'kitchen.order.label': 'Món ăn',
  'kitchen.staff.label': 'Nhân viên order',
  'kitchen.history-order.label': 'Lịch sử order',
  'kitchen.time.label': 'Thời gian',

  'sidebar.kitchen-group': 'Quản lý bếp',

  'kitchen.tab-all': 'Tất cả',
  'kitchen.tab-doing': 'Danh sách đang làm',
  'kitchen.tab-done': 'Danh sách hoàn thành',
  'kitchen.status.label': 'Trạng thái',
  'kitchen.group.label': 'Lầu',
  'sidebar.kitchen-list': 'Quản lý món ăn',
  'sidebar.kitchen.dish.cancel': 'Quản lý hủy món',

  'kitchen.product-selected.label': 'Sản phẩm đã chọn',
  'kitchen.product-list.label': 'Danh sách sản phẩm',

  'kitchen.selected.all': 'Chọn tất cả',
  'kitchen.selected.not-all': 'Bỏ chọn tất',
  'kitchen.breadcrumb.title': 'Danh sách bếp',
  'kitchen.order.waiting': 'Chờ xử lý',
  'kitchen.order.printed': 'Đã in',
  'kitchen.reason.label': 'Lý do hủy món',
  'kitchen.confirm.dish.cancel': 'Xác nhận hủy món ăn',
  'kitchen.confirm-sure-approve.dish.cancel': 'Bạn chắc chắn chấp nhận yêu cầu hủy món ăn?',
  'kitchen.confirm-sure-reject.dish.cancel': 'Bạn chắc chắn từ chối yêu cầu hủy món ăn?',
  'kitchen.order.dish-cancel.waiting': 'Chờ duyệt',
  'kitchen.order.dish-cancel.approve': 'Chấp nhận',
  'kitchen.order.dish-cancel.reject': 'Từ chối',
  'kitchen.order.complete': 'Hoàn thành',
  'kitchen.fnb.tab-kitchen-waiting': 'Danh sách chờ',
  'kitchen.fnb.tab-kitchen-complete': 'Danh sách hoàn thành',
  'kitchen.fnb.complete-fail': 'Chuyển thất bại!',
  'kitchen.fnb.complete-success': 'Chuyển thành công!',
  'kitchen.fnb.col.stt': 'STT',
  'kitchen.fnb.col.code': 'Mã HD',
  'kitchen.fnb.col.kitchen-name': 'Tên bếp',
  'kitchen.fnb.col.dishes-name': 'Món ăn',
  'kitchen.fnb.col.staff-name': 'Nhân viên',
  'kitchen.fnb.col.status': 'Trạng thái',
  'kitchen.fnb.col.time': 'Thời gian',
  'kitchen.fnb.col.action': 'Hoạt động',
  'kitchen.fnb.new-dishes-kitchen': 'Có đơn mới!',
  'kitchen.fnb.dishes-kitchen-done': 'Món ăn đã làm xong!',

  'kitchen.breadcrumb.alarm.title': 'Âm thanh thông báo',
  'kitchen.alarm.btn-create': 'Thêm âm thanh',
  'kitchen.alarm.name.label': 'Tên âm thanh',
  'kitchen.alarm.source.label': 'Nguồn',
};

export default vnLangKitchen;
