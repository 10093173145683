const usLangTable = {
  'table.helmet': 'List table',
  'table.breadcrumb.title': 'List table',
  'table.breadcrumb.parent': 'Sell',
  'table.modal.create.title': 'Add table',
  'table.modal.create.confirm.title': 'Confirm add new table',
  'table.modal.create.confirm.description': 'You definitely want to add this table?',
  'table.modal.detail.title': 'Table Details',
  'table.modal.update.title': 'Update desk information',
  'table.modal.update.confirm.title': 'Confirm the update of desk information',
  'table.modal.update.confirm.description': 'Definitely want to update this desk information?',
  'table.modal.delete.title': 'Confirm the deletion of the table',
  'table.modal.delete.description': 'Are you sure you want to clear this table?',
  'table.modal.reset-password.title': 'Reset Password',
  'table.noti.create.success': 'Add a successful table!',
  'table.noti.create.failure': 'Adding a table failed! Please try again later.',
  'table.noti.update.success': 'Successful table update!',
  'table.noti.update.failure': 'Failed to update the table! Please try again later.',
  'table.noti.delete.success': 'Successfully delete the table!',
  'table.noti.delete.failure': 'Failed to clear the table! Please try again later.',
  'table.btn-create': 'Add',
  'table.name.col': 'Table name',
  'table.table.col': 'List table',
  'table.name.label': 'Table name',
  'table.point-qr.label': 'QR accumulate points',
  'table.qr.label': 'QR code',
  'table.name.placeholder': 'Input table name',
  'table.name.error.required': 'Please enter the table name!',
  'table.table.label': 'Table area',
  'table.table.placeholder': 'Select a table area',
  'table.active.label': 'Activate',
  'table.active.placeholder': 'Activate',
  'table.group.label': 'Table area',
  'table.group.label.place': 'Select a table area',
  'table.edit.status': 'Edit',
  'table.request.payment.status': 'Payment',
  'print.qr.lable': 'Please scan the QR code to order',
  'print.qr.lable.conact.staff': 'Contact staff for assistance',
};

export default usLangTable;
