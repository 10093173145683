const usLangSupplier = {
  'supplier.helmet': 'List of suppliers',
  'supplier.breadcrumb.title': 'List of suppliers',
  'supplier.breadcrumb.parent': 'Sell',
  'supplier.modal.create.title': 'Add supplier',
  'supplier.modal.create.confirm.title': 'Confirm new suppliers',
  'supplier.modal.create.confirm.description': 'You definitely want to add this supplier?',
  'supplier.modal.detail.title': 'the detail information of supplier',
  'supplier.modal.update.title': 'Update supplier information',
  'supplier.modal.update.confirm.title': 'Confirmation of supplier information updates',
  'supplier.modal.update.confirm.description': 'You definitely want to update this supplier information?',
  'supplier.modal.delete.title': 'supplier deletion confirmation',
  'supplier.modal.delete.description': 'You definitely want to delete this supplier?',
  'supplier.modal.reset-password.title': 'Reset Password',
  'supplier.noti.create.success': 'Add successful suppliers!',
  'supplier.noti.create.failure': 'Add unsuccessful suppliers!Please try again later.',
  'supplier.noti.update.success': 'Update the supplier successfully!',
  'supplier.noti.update.failure': 'Update the supplier unsuccessful!Please try again later.',
  'supplier.noti.delete.success': 'Delete suppliers successfully!',
  'supplier.noti.delete.failure': 'Delete suppliers failed!Please try again later.',
  'supplier.noti.reset-password.success': 'Retite a successful supplier password!',
  'supplier.noti.reset-password.failure': 'Reset the supplier password failed!Please try again later.',
  'supplier.btn-create': 'Create',
  'supplier.error-min-length': 'Minimum $x character!',
  'supplier.error-max-length': 'Maximum $x character!',
  'supplier.code.col': 'supplier code',
  'supplier.code.label': 'supplier code',
  'supplier.code.placeholder': 'Enter the supplier code',
  'supplier.barcode.col': 'Barcode',
  'supplier.barcode.label': 'Barcode',
  'supplier.barcode.placeholder': 'Enter the barcode',
  'supplier.categories.col': 'supplier line',
  'supplier.categories.label': 'supplier line',
  'supplier.categories.placeholder': 'Choose a supplier line',
  'supplier.categories.error.required': 'Please choose the supplier line!',
  'supplier.name.col': "supplier's name",
  'supplier.name.label': "supplier's name",
  'supplier.name.placeholder': 'Enter the name of the supplier',
  'supplier.name.error.required': 'Please enter the supplier name!',
  'supplier.desc.col': 'Describe',
  'supplier.desc.label': 'supplier Description',
  'supplier.desc.placeholder': 'Enter the supplier description',
  'supplier.unit.col': 'Unit',
  'supplier.unit.label': 'Basic unit',
  'supplier.unit.placeholder': 'Enter the basic unit',
  'supplier.unit.error-required': 'Please enter the basic unit!',
  'supplier.price.col': 'Price',
  'supplier.price.label': 'Price (VND)',
  'supplier.price.placeholder': 'Enter the selling price',
  'supplier.price.error-required': 'Please enter the selling price!',
  'supplier.price.invalid': 'Enter the price greater than 0!',
  'supplier.units.name.label': 'Unit name',
  'supplier.units.name.placeholder': 'Enter the name of the unit',
  'supplier.units.name.error-required': 'Please enter the name of the unit!',
  'supplier.units.number.label': 'Exchange value',
  'supplier.units.number.placeholder': 'Enter the conversion value',
  'supplier.units.number.error-required': 'Please enter the conversion value!',
  'supplier.units.number.error-invalid': 'The conversion value must be greater than 0!',
  'supplier.units.price.label': 'Price (VND)',
  'supplier.units.price.placeholder': 'Enter the selling price',
  'supplier.units.price.error-required': 'Please enter the selling price!',
  'supplier.units.code.label': 'supplier code',
  'supplier.units.code.placeholder': 'Enter the supplier code',
  'supplier.units.barcode.label': 'Barcode',
  'supplier.units.barcode.placeholder': 'Enter the barcode',
  'supplier.image.col': 'Image',
  'supplier.image.label': 'Upload',
  'supplier.image.label-2': 'supplier image',
  'supplier.image.error-required': 'Please upload the supplier photo!',
  'supplier.btn-add-units-items': 'Add unit',
  'supplier.email.validate': 'Email not valid format',
  'supplier.phone.label': 'Supplier phone',
  'supplier.phone.placeholder': 'Enter supplier phone',
  'supplier.email.label': 'Supplier email',
  'supplier.email.placeholder': 'Enter supplier email',
  'supplier.address.label': 'Address',
  'supplier.address.placeholder': 'Enter supplier address ',
};

export default usLangSupplier;
