const employee = {
  //
  'employee.list': 'Danh sách nhân viên',
  'employee.manager': 'Quản lý nhân viên',
  'employee.add': 'Thêm nhân viên',
  'employee.edit': 'Chỉnh sửa nhân viên',
  'employee.information': 'Thông tin nhân viên',
  'employee.information-contact': 'Thông tin liên lạc',
  'employee.information-personal': 'Thông tin cá nhân',
  'employee.confirm-add': 'Xác nhận thêm nhân viên',
  'employee.confirm-sure-add': 'Bạn chắc chắn muốn thêm nhân viên này?',
  'employee.confirm-edit': 'Xác nhận chỉnh sửa nhân viên',
  'employee.confirm-sure-edit': 'Bạn chắc chắn muốn chỉnh sửa nhân viên này?',
  'employee.confirm-delete': 'Xác nhận xóa nhân viên',
  'employee.confirm-sure-delete': 'Bạn chắc chắn muốn xóa nhân viên này?',
  'employee.confirm-reset': 'Xác nhận đặt lại mật khẩu nhân viên này?',
  // form
  'employee.code': 'ID',
  'employee.name': 'Họ và tên',
  'employee.gender': 'Giới tính',
  'employee.gender.male': 'Nam',
  'employee.gender.female': 'Nữ',
  'employee.birth': 'Sinh nhật',
  'employee.marital-status': 'Tình trạng hôn nhân',
  'employee.id-personal-date': 'Ngày cấp ',
  'employee.id-personal-place': 'Nơi cấp ',
  'employee.phone': 'Số điện thoại',
  'employee.image': 'Hình ảnh',
  'employee.password': 'Mật khẩu',
  'employee.address': 'Địa chỉ',
  'employee.disable': 'Chưa kích hoạt',
  'employee.enable': 'Kích hoạt',
  'employee.label': 'Ký hiệu',
  'employee.telephone': 'SDT công việc',
  'employee.position': 'Chức vụ',
  'employee.department': 'Phòng',
  'employee.start-date-work': 'Ngày bắt đầu',
  'employee.timeFrom': 'Giờ bắt đầu',
  'employee.timeTo': 'Giờ kết thúc',
  'employee.cv-status': 'Trạng thái hồ sơ',
  'employee.account-status': 'Tình trạng tài khoản',
  'employee.status.submitted': 'Đã nộp',
  'employee.status.unSubmitted': 'Chưa nộp',
  'employee.statusMarital.married': 'Đã kết hôn',
  'employee.statusMarital.single': 'Độc thân',
  'employee.statusGender.male': 'Nam giới',
  'employee.statusGender.female': 'Nữ giới',
  'employee.statusGender.unspecified': 'Tôi không muốn công khai',
  'employee.role.employee': 'Nhân viên',
  'employee.role.manager': 'Giám đốc',
  'employee.role.admin': 'Quản trị viên',

  'employee.resetPassword.default': 'Đặt lại mật khẩu mặc định',
  'employee.resetPassword.gmail': 'Gửi đến Gmail',
  'employee.resetPassword.way': 'Chọn cách nhận mật khẩu mới',
  'employee.resetPassword.newPassword': 'Mật khẩu mới :',

  // notification

  'employee.addSuccess': 'Thêm nhân viên thành công',
  'employee.addFailure': 'Thêm thất bại của nhân viên',
  'employee.editSuccess': 'Chỉnh sửa thành công nhân viên',
  'employee.editFailure': 'Chỉnh sửa thất bại của nhân viên',
  'employee.deleteSuccess': 'Xóa nhân viên thành công',
  'employee.deleteFailure': 'Xóa thất bại của nhân viên',
  'employee.resetSuccess': 'Đặt lại mật khẩu nhân viên thành công',
  'employee.resetFailure': 'Đặt lại mật khẩu thất bại của nhân viên',

  // validate
  'employee.name-required': 'Vui lòng nhập họ tên nhân viên',
  'employee.name-min-length': 'Ít nhất 4 ký tự',
  'employee.phone-require': 'Vui lòng nhập số điện thoại nhân viên',
  'employee.phone-valid': 'Số điện thoại không hợp lệ',
  'employee.phone-length': 'Số điện thoại không được vượt quá 11 ký tự',
  'employee.email-require': 'Vui lòng nhập email',
  'employee.email-valid': 'Email không được định dạng chính xác',
  'employee.email-exist': 'Email đã tồn tại',
  'employee.password-require': 'Mật khẩu là bắt buộc',
  'employee.password-valid': 'Mật khẩu phải chứa ít nhất 1 chữ thường, 1 chữ in hoa, 1 số và 1 ký tự đặc biệt',

  //

  'employee.config.breadcrumb.heading': 'Cấu hình lịch làm việc',
  'employee.config.breadcrumb.parent': 'Quản lý cấu hình lịch làm việc',
  'employee.config.helmet': 'Cấu hình lịch làm việc',
  'employee.config.create': 'Tạo cấu hình lịch làm việc',
  'employee.config.save.success': 'Lưu cấu hình lịch làm việc thành công',
  'employee.config.save.failure': 'Lưu cấu hình lịch làm việc thất bại',
};

const vnLangEmployee = {
  ...employee,
};

export default vnLangEmployee;
