const vnLangCancelOrder = {
  'cancel.order.helmet': 'Danh sách hủy món',
  'cancel.order.breadcrumb.title': 'Danh sách hủy món',
  'cancel.order.breadcrumb.parent': 'Bán hàng',
  'cancel.order.product.name.label': 'Món hủy',
  'cancel.order.product.image.label': 'Hình ảnh',
  'cancel.order.modal.update.title': 'Cập nhật yêu cầu hủy món',
  'cancel.order.modal.update.reason': 'Lý do hủy món',
  'cancel.order.modal.update.confirm': 'Xác nhận hủy món',
  'cancel.order.modal.update.confirm.success': 'Xác nhận hủy món thành công!',
  'cancel.order.modal.update.reject.confirm.success': 'Xác nhận từ chối hủy món thành công!',
  'cancel.order.modal.update.confirm.title': 'Xác nhận hủy món',
  'cancel.order.modal.update.confirm.description': 'Bạn chắc chắn muốn hủy món?',
  'cancel.order.modal.update.reject.confirm.title': 'Xác nhận từ chối hủy món',
  'cancel.order.modal.update.reject.confirm.description': 'Bạn chắc chắn muốn từ chối việc hủy món?',
};

export default vnLangCancelOrder;
