const vnLangPackageBought = {
  'package.bought.helmet': 'Gói dịch vụ đã mua',
  'package.bought.breadcrumb.title': 'Danh sách gói dịch vụ',
  'package.bought.btn-create': 'Mua gói dịch vụ',
  'package.bought.payment': 'Hình thức thanh toán',

  'package.bought.create': 'Mua gói dịch vụ',
  'package.bought.list': 'Danh sách gói dịch vụ',
  'package.bought.edit': 'Cập nhật gói dịch vụ',
  'package.bought.delete': 'Xóa gói dịch vụ',
  'package.bought.manage': 'Quản lý gói dịch vụ',
  'package.bought.create.confirm': 'Xác nhận mua gói dịch vụ',
  'package.bought.create.confirmSure': 'Bạn chắc chắn mua gói dịch vụ?',
  'package.bought.edit.confirm': 'Xác nhận cập nhật gói dịch vụ',
  'package.bought.edit.confirmSure': 'Bạn chắc chắn cập nhật gói dịch vụ?',
  'package.bought.create.success': 'Tạo gói dịch vụ thành công',
  'package.bought.update.success': 'Cập nhật gói dịch vụ thành công',
  'package.bought.delete.confirm': 'Xác nhận xóa gói dịch vụ',
  'package.bought.delete.confirmSure': 'Bạn chắc chắn xóa gói dịch vụ?',
  'package.bought.delete.success': 'Xóa gói dịch vụ thành công',

  'package.bought.name': 'Tên gói dịch vụ',
  'package.bought.money': 'Giá',
  'package.bought.month': 'Số tháng sử dụng',
  'package.bought.items': 'Các tính năng',
  'package.bought.start': 'Ngày bắt đầu',
  'package.bought.end': 'Ngày kết thúc',
  'package.bought.type': 'Loại',
  'package.bought.feature': 'Chức năng',
  'package.bought.package': 'Gói',
  'package.bought.status': 'Trạng thái',
  'package.bought.success': 'Thành công',
  'package.bought.waiting': 'Chờ duyệt',
  'package.bought.fail': 'Từ chối',
  'package.bought.package.choose': 'Chọn gói',
  'package.bought.feature.choose': 'Chọn chức năng',
  'package.bought.total': 'Tổng tiền cần thanh toán',
  'package.bought.period': 'Gói $x tháng',
  'package.bought.buy': 'Mua',

  //

  'package.bought.ticket.success': 'Thanh toán thành công',
  'package.bought.ticket.desc.0': 'Thanh toán của bạn đã được xử lý!',
  'package.bought.ticket.desc.1': 'Chi tiết giao dịch ở dưới',
  'package.bought.ticket.number': 'Mã giao dịch',
  'package.bought.ticket.package': 'Gói',
  'package.bought.ticket.money': 'Giá tiền',
  'package.bought.ticket.from_date': 'Từ ngày',
  'package.bought.ticket.to_date': 'Đến ngày',

};

export default vnLangPackageBought;
