const productLookup = {
  'product-lookup.helmet': 'Tìm kiếm sản phẩm',
  'product-lookup.title': 'Tìm kiếm sản phẩm',
  'product-lookup.name': 'Tên sản phẩm',
  'product-lookup.code': 'Mã sản phẩm',
  'product-lookup.unit': 'Đơn vị tính',
  'product-lookup.price.capital': 'Giá nhập',
  'product-lookup.price.sale': 'Giá bán',
  'product-lookup.inventory': 'Tồn kho',
  'product-lookup.inventory.nQuantity': 'Không quản lý số lượng',
  'product-lookup.expire': 'Hạn sử dụng',
  'product-lookup.image': 'Hình ảnh',
};

const vnLangProductLookup = {
  ...productLookup,
};

export default vnLangProductLookup;
