import usLangUnit from './Unit/us';
import usLangCategory from './Category/us';
import usLangSupplier from './Supplier/us';
import usLangWarehouse from './Warehouse/us';
import usLangActionWarehouse from './ActionWarehouse/us';
import usLangDiscount from './Discount/us';
import usLangProductLookup from './SearchProduct/us';
import usLangKitchen from './Kitchen/us';
import usLangPackageBought from './PackageBought/us';
import usLangPromotion from './Promotion/us';
import usLangTable from './Table/us';
import usLangCancelOrder from './CancelOrder/us';

const usLangSell = {
  ...usLangUnit,
  ...usLangCategory,
  ...usLangSupplier,
  ...usLangWarehouse,
  ...usLangActionWarehouse,
  ...usLangDiscount,
  ...usLangProductLookup,
  ...usLangTable,
  ...usLangKitchen,
  ...usLangPackageBought,
  ...usLangPromotion,
  ...usLangCancelOrder,
};

export default usLangSell;
