/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllDishCancelReport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/dish-cancel', { params: payload }));
    yield put(Actions.getAllDishCancelReportSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.getAllDishCancelReportFailure(message));
    }
  }
}

function* getAllDishCancelUserReport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/user-dish-cancel', { params: payload }));
    yield put(Actions.getAllDishCancelUserReportSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.getAllDishCancelUserReportFailure(message));
    }
  }
}

function* getRevenueReport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/revenue', { params: payload }));
    yield put(Actions.getRevenueReportSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const message = e.response.data;
      yield put(Actions.getRevenueReportFailure(message));
    }
  }
}

function* getAllProductsInstocks({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/reports/product-instocks?${payload}`)
        : () => axiosMicro.get('/reports/product-instocks', { params: payload }),
    );
    yield put(Actions.getAllProductsInstocksSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductsInstocksFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllDishCancelReportRequest, getAllDishCancelReport);
  yield takeLatest(Actions.getAllDishCancelUserReportRequest, getAllDishCancelUserReport);
  yield takeLatest(Actions.getRevenueReportRequest, getRevenueReport);
  yield takeLatest(Actions.getAllProductsInstocksRequest, getAllProductsInstocks);
}
