const vnLangTableGroup = {
  'table.group.helmet': 'Danh sách khu vực bàn',
  'table.group.breadcrumb.title': 'Danh sách khu vực bàn',
  'table.group.breadcrumb.parent': 'Bán hàng',
  'table.group.modal.create.title': 'Thêm khu vực bàn',
  'table.group.modal.create.confirm.title': 'Xác nhận thêm khu vực bàn mới',
  'table.group.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm khu vực bàn này?',
  'table.group.modal.detail.title': 'Thông tin chi tiết khu vực bàn',
  'table.group.modal.update.title': 'Cập nhật thông tin khu vực bàn',
  'table.group.modal.update.confirm.title': 'Xác nhận cập nhật thông tin khu vực bàn',
  'table.group.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin khu vực bàn này?',
  'table.group.modal.delete.title': 'Xác nhận xoá khu vực bàn',
  'table.group.modal.delete.description': 'Bạn chắc chắn muốn xoá khu vực bàn này?',
  'table.group.modal.reset-password.title': 'Đặt lại mật khẩu',
  'table.group.noti.create.success': 'Thêm khu vực bàn thành công!',
  'table.group.noti.create.failure': 'Thêm khu vực bàn không thành công! Vui lòng thử lại sau.',
  'table.group.noti.update.success': 'Cập nhật khu vực bàn thành công!',
  'table.group.noti.update.failure': 'Cập nhật khu vực bàn không thành công! Vui lòng thử lại sau.',
  'table.group.noti.delete.success': 'Xoá khu vực bàn thành công!',
  'table.group.noti.delete.failure': 'Xoá khu vực bàn không thành công! Vui lòng thử lại sau.',
  'table.group.btn-create': 'Thêm',
  'table.group.name.col': 'Tên khu vực bàn',
  'table.group.table.col': 'Danh sách bàn',
  'table.group.name.label': 'Tên khu vực bàn',
  'table.group.name.placeholder': 'Nhập tên khu vực bàn',
  'table.group.name.error.required': 'Vui lòng nhập tên khu vực bàn!',
  'table.group.table.label': 'Tạo nhanh danh sách bàn',
  'table.group.table.placeholder': 'Nhập số lượng bàn',
  'table.group.active.label': 'Kích hoạt khu vực',
  'table.group.active.placeholder': 'Kích hoạt khu vực',
};

export default vnLangTableGroup;
