const usLangCashier = {
  'sidebar.cashier': 'Cashier',
  'sidebar.book.table': 'List book table',
  'sidebar.order.history': 'Order history',
  'list-book-table-all.breadcrumb.title': 'List book table',
  'list-book-order-all.breadcrumb.parent': 'Manage table reservations',
  'list-book-table-all.breadcrumb.title.history': 'Order history',
  'list-orders-history.breadcrumb': 'Manage table reservation history',
  'list-orders-all.btn.update': 'Update',
  'list-orders-all.modal.update.confirm.title': 'Confirmation of book table information updates',
  'list-orders-all.modal.update.confirm.description': 'You definitely want to update this booking information?',
  'list-orders.noti.update.success': 'Update order success',
  'list-orders-all.noti.delete.failure': 'Delete orders failed!Please try again later.',
  'list-orders-all.modal.delete.title': 'Confirm deletion of table order',
  'list-orders-all.modal.delete.description': 'Are you sure you want to delete this reservation?',
  'list-orders.noti.delete.success': 'Delete order success!',
  'list-orders-all.modal.order-status.label': 'Payment method',
  'list-orders-all.modal.paid.label': 'Paid',
};
export default usLangCashier;
