// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const setUserChat = createAction('SET_USER_CHAT');

export const signUpRequest = createAction('SIGN_UP_REQUEST');
export const signUpSuccess = createAction('SIGN_UP_SUCCESS');
export const signUpFailure = createAction('SIGN_UP_FAILURE');
export const resetSignUp = createAction('RESET_SIGN_UP');

export const signInRequest = createAction('SIGN_IN_REQUEST');
export const signInSuccess = createAction('SIGN_IN_SUCCESS');
export const signInFailure = createAction('SIGN_IN_FAILURE');

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordFailure = createAction('CHANGE_PASSWORD_FAILURE');
export const resetChangePasswordState = createAction(
  'RESET_CHANGE_PASSWORD_STATE',
);

export const requireChangePasswordRequest = createAction(
  'REQUIRE_CHANGE_PASSWORD_REQUEST',
);
export const requireChangePasswordSuccess = createAction(
  'REQUIRE_CHANGE_PASSWORD_SUCCESS',
);
export const requireChangePasswordFailure = createAction(
  'REQUIRE_CHANGE_PASSWORD_FAILURE',
);
export const resetRequireChangePasswordState = createAction(
  'RESET_REQUIRE_CHANGE_PASSWORD_STATE',
);
export const registerRequest = createAction('REGISTER_REQUEST');
export const registerSuccess = createAction('REGISTER_SUCCESS');
export const registerFailure = createAction('REGISTER_FAILURE');
export const resetRegisterState = createAction(
  'RESET_REGISTER_STATE',
);

export const signInWithGoogleRequest = createAction('SIGN_IN_WITH_GOOGLE_REQUEST');
export const signInWithGoogleSuccess = createAction('SIGN_IN_WITH_GOOGLE_SUCCESS');
export const signInWithGoogleFailure = createAction('SIGN_IN_WITH_GOOGLE_FAILURE');

export const createOTPRequest = createAction('CREATE_OTP_REQUEST');
export const createOTPRequestSuccess = createAction('CREATE_OTP_REQUEST_SUCCESS');
export const createOTPRequestFailure = createAction('CREATE_OTP_REQUEST_FAILURE');
export const resetCreateOTPState = createAction(
  'RESET_CREATE_OTP_STATE',
);
export const checkOtpRequest = createAction('CHECK_OTP_REQUEST');
export const checkOtpRequestSuccess = createAction('CHECK_OTP_REQUEST_SUCCESS');
export const checkOtpRequestFailure = createAction('CHECK_OTP_REQUEST_FAILURE');
export const resetCheckOTPState = createAction(
  'RESET_CHECK_OTP_STATE',
);

export const resetUserPasswordRequest = createAction('RESET_USER_PASSWORD_REQUEST');
export const resetUserPasswordRequestSuccess = createAction('RESET_USER_PASSWORD_REQUEST_SUCCESS');
export const resetUserPasswordRequestFailure = createAction('RESET_USER_PASSWORD_REQUEST_FAILURE');
export const resetUserPasswordState = createAction(
  'RESET_USER_PASSWORD_STATE',
);
export const resetAuthState = createAction('RESET_AUTH_STATE');
