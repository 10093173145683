const usLangPackageBought = {
  'package.bought.helmet': 'Service package purchased',
  'package.bought.breadcrumb.title': 'List of service packages',
  'package.bought.btn-create': 'Buy service package',
  'package.bought.payment': 'Payments',

  'package.bought.create': 'Buy Service Package',
  'package.bought.list': 'List of service packages',
  'package.bought.edit': 'Update service package',
  'package.bought.delete': 'Delete service package',
  'package.bought.manage': 'Service package management',
  'package.bought.create.confirm': 'Confirm the purchase of service package',
  'package.bought.create.confirmSure': 'You definitely buy a service pack?',
  'package.bought.edit.confirm': 'Confirmation of service package update',
  'package.bought.edit.confirmSure': 'You definitely update the service package?',
  'package.bought.create.success': 'Create successful service package',
  'package.bought.update.success': 'Update successful service package',
  'package.bought.delete.confirm': 'Confirm the deletion of the service package',
  'package.bought.delete.confirmSure': 'You definitely delete the service package?',
  'package.bought.delete.success': 'Delete successful service package',

  'package.bought.name': 'Service package name',
  'package.bought.money': 'Price',
  'package.bought.month': 'Number of months of use',
  'package.bought.items': 'Functions',
  'package.bought.start': 'Start day',
  'package.bought.end': 'End date',
  'package.bought.type': 'Type',
  'package.bought.feature': 'Function',
  'package.bought.package': 'Package',
  'package.bought.status': 'Status',
  'package.bought.success': 'Success',
  'package.bought.waiting': 'Pending',
  'package.bought.fail': 'Refuse',
  'package.bought.package.choose': 'Select package',
  'package.bought.feature.choose': 'Choose function',
  'package.bought.total': 'Total money to pay',
  'package.bought.period': 'Package $x month',

  'package.bought.ticket.success': 'Ticket payment successfully',
  'package.bought.ticket.desc.0': 'Your payment has been processed!',
  'package.bought.ticket.desc.1': 'Details of transaction are included below',
  'package.bought.ticket.number': 'Transaction number',
  'package.bought.ticket.package': 'Package',
  'package.bought.ticket.money': 'Money',
  'package.bought.ticket.from_date': 'From date',
  'package.bought.ticket.to_date': 'To date',
};

export default usLangPackageBought;
