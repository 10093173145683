const vnLangCustomer = {
  'customer.helmet': 'Danh sách khách hàng',
  'customer.breadcrumb.title': 'Danh sách khách hàng',
  'customer.breadcrumb.parent': 'Quản lý người dùng',
  'customer.modal.create.title': 'Thêm khách hàng',
  'customer.modal.create.confirm.title': 'Xác nhận thêm khách hàng mới',
  'customer.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm khách hàng này?',
  'customer.modal.detail.title': 'Thông tin chi tiết khách hàng',
  'customer.modal.update.title': 'Cập nhật thông tin khách hàng',
  'customer.modal.update.confirm.title': 'Xác nhận cập nhật thông tin khách hàng',
  'customer.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin khách hàng này?',
  'customer.modal.delete.title': 'Xác nhận xoá khách hàng',
  'customer.modal.delete.description': 'Bạn chắc chắn muốn xoá khách hàng này?',
  'customer.modal.reset-password.title': 'Đặt lại mật khẩu',
  'customer.noti.create.success': 'Thêm khách hàng thành công!',
  'customer.noti.create.failure': 'Thêm khách hàng không thành công! Vui lòng thử lại sau.',
  'customer.noti.update.success': 'Cập nhật khách hàng thành công!',
  'customer.noti.update.failure': 'Cập nhật khách hàng không thành công! Vui lòng thử lại sau.',
  'customer.noti.delete.success': 'Xoá khách hàng thành công!',
  'customer.noti.delete.failure': 'Xoá khách hàng không thành công! Vui lòng thử lại sau.',
  'customer.noti.reset-password.success': 'Đặt lại mật khẩu khách hàng thành công!',
  'customer.noti.reset-password.failure': 'Đặt lại mật khẩu khách hàng không thành công! Vui lòng thử lại sau.',
  'customer.btn-create': 'Thêm',
  'customer.name.col': 'Họ và tên',
  'customer.name.label': 'Họ và tên',
  'customer.name.placeholder': 'Nhập họ và tên',
  'customer.name.error-required': 'Vui lòng nhập Họ và tên!',
  'customer.name.error-min-length': 'Tối thiểu $x ký tự!',
  'customer.name.error-max-length': 'Tối đa $x ký tự!',
  'customer.phone.col': 'Số điện thoại',
  'customer.phone.label': 'Số điện thoại',
  'customer.phone.placeholder': 'Nhập số điện thoại',
  'customer.phone.error-required': 'Vui lòng nhập Số điện thoại!',
  'customer.phone.error-invalid': 'Số điện thoại không hợp lệ!',
  'customer.email.col': 'Gmail',
  'customer.email.label': 'Gmail',
  'customer.email.placeholder': 'Nhập gmail',
  'customer.email.error-required': 'Vui lòng nhập Gmail!',
  'customer.email.error-invalid': 'Gmail không hợp lệ!',
  'customer.address.col': 'Địa chỉ',
  'customer.address.label': 'Địa chỉ',
  'customer.address.placeholder': 'Nhập địa chỉ',
  'customer.personal': 'Khách hàng',
  'customer.agency': 'Đại lý',
  'customer.work': 'Loại khách hàng',
  'customer.agency.level': 'Cấp đại lý',
  'customer.staff.charge': 'Nhân viên phụ trách',

};

export default vnLangCustomer;
