const vnLangTable = {
  'table.helmet': 'Danh sách bàn',
  'table.breadcrumb.title': 'Danh sách bàn',
  'table.breadcrumb.parent': 'Bán hàng',
  'table.modal.create.title': 'Thêm bàn',
  'table.modal.create.confirm.title': 'Xác nhận thêm bàn mới',
  'table.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm bàn này?',
  'table.modal.detail.title': 'Thông tin chi tiết bàn',
  'table.modal.update.title': 'Cập nhật thông tin bàn',
  'table.modal.update.confirm.title': 'Xác nhận cập nhật thông tin bàn',
  'table.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin bàn này?',
  'table.modal.delete.title': 'Xác nhận xoá bàn',
  'table.modal.delete.description': 'Bạn chắc chắn muốn xoá bàn này?',
  'table.modal.reset-password.title': 'Đặt lại mật khẩu',
  'table.noti.create.success': 'Thêm bàn thành công!',
  'table.noti.create.failure': 'Thêm bàn không thành công! Vui lòng thử lại sau.',
  'table.noti.update.success': 'Cập nhật bàn thành công!',
  'table.noti.update.failure': 'Cập nhật bàn không thành công! Vui lòng thử lại sau.',
  'table.noti.delete.success': 'Xoá bàn thành công!',
  'table.noti.delete.failure': 'Xoá bàn không thành công! Vui lòng thử lại sau.',
  'table.btn-create': 'Thêm',
  'table.name.col': 'Tên bàn',
  'table.table.col': 'Danh sách bàn',
  'table.name.label': 'Tên bàn',
  'table.point-qr.label': 'QR tích điểm',
  'table.qr.label': 'QR code',
  'table.name.placeholder': 'Nhập tên bàn',
  'table.name.error.required': 'Vui lòng nhập tên bàn!',
  'table.table.label': 'Khu vực bàn',
  'table.table.placeholder': 'Chọn khu vực bàn',
  'table.active.label': 'Kích hoạt',
  'table.active.placeholder': 'Kích hoạt',
  'table.group.label': 'Khu vực bàn',
  'table.group.label.place': 'Chọn khu vực bàn',
  'table.edit.status': 'Chỉnh sửa',
  'table.request.payment.status': 'Thanh toán',

  'print.qr.lable': 'Vui lòng quét mã QR code để gọi món',
  'print.qr.lable.conact.staff': 'Liên hệ nhân viên để được hỗ trợ',
};

export default vnLangTable;
